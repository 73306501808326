import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { isEmpty, get, cloneDeep } from 'lodash-es';
import AdvancedSettings from '../../../../components/common/advancedSettings';
import RichTextEditor from '../../../../components/RichTextEditor';
import RichTextEditor2 from '../../../../components/RichTextEditor2';
import CustomButton from '../../../../components/common/button';
import withRouter from '../../../../helpers/withRouter';
import './index.scss';
import DragDropComponent from '../../../../components/common/reactSortable';
import { useGetSectionQuery, useSectionUpdateMutation } from '../../../../api/apiSlice';
import { useInstance } from '../../../../hooks';
import { updateStylesheet } from '../../../../../../Utils';

const handleOnDragEnterOver = (e) => {
  e.preventDefault();
  e.dataTransfer.dropEffect = 'none';
};

function ContentEditor(props) {
  const {
    section: { id: sectionId },
    params: { instanceId, siteId },
    isEmailPage,
    submitCounter,
    postSave,
    refetchPageData,
  } = props;

  const instance = useInstance();

  const { data: section } = useGetSectionQuery({ instanceId, sectionId }, { skip: isEmpty(instanceId) && isEmpty(sectionId) });

  const [sectionUpdate] = useSectionUpdateMutation();

  const [tempSection, setTempSection] = useState(null);

  const textBlocks = get(tempSection, 'text_blocks', []);

  const tempTextBlocks = [...textBlocks];

  if (section && (tempSection === null || section?.id !== tempSection?.id)) {
    setTempSection(cloneDeep(section));
  }

  const tempData = { ...tempSection };

  const sendParams = {
    id: tempSection?.id,
    instance_id: instanceId,
    page_version_id: tempSection?.page_version_id,
  };

  const onSave = async (payload) => {
    const tempPayload = { ...payload, ...sendParams };
    tempPayload.text_blocks_attributes = payload.text_blocks;
    delete tempPayload.text_blocks;
    const res = await sectionUpdate(tempPayload);
    if (!isEmpty(res) && res.data.meta.is_success) {
      refetchPageData();
      setTempSection(res.data.data);
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (submitCounter) {
      (async () => {
        await onSave(tempSection);
        postSave();
        if (!isEmailPage) {
          updateStylesheet({ siteId, pageId: tempSection?.page_id, pageVersionId: tempSection?.page_version_id });
        }
      })();
    }
  }, [submitCounter, isEmailPage]);

  const onHandleAddNew = async (type, order) => {
    const newTb = {
      type,
      block_text: '',
      line_break: 0,
      order,
      file_id: null,
      section_content_id: tempData.id,
      text_style_id: '',
    };

    setTempSection({ ...tempSection, text_blocks: [...tempSection.text_blocks, newTb] });
    onSave({ text_blocks: [...tempSection.text_blocks, newTb] });
  };

  const onHandleDeleteEditor = async (textBlock) => {
    const updatedBlocks = tempData.text_blocks.filter((t) => t.id !== textBlock.id);
    setTempSection({ ...tempSection, text_blocks: updatedBlocks });
    onSave({ ...tempSection, text_blocks: updatedBlocks });
  };

  const updateParams = (newState) => {
    newState.forEach((tb, i) => tb.order = i + 1);
    tempData.text_blocks = newState;
    setTempSection(tempData);
  };

  const onUpdateTextBlock = (textBlock, index) => {
    if (!isEmpty(tempTextBlocks[index])) {
      tempTextBlocks[index] = textBlock;
      setTempSection({ ...tempSection, text_blocks: tempTextBlocks });
    }
  };

  const onHandleMove = (index, moveUp) => {
    const setIndex = moveUp ? index - 1 : index + 1;
    if (!isEmpty(tempTextBlocks[index])) {
      tempTextBlocks[index] = {
        ...tempTextBlocks[index],
        order: setIndex + 1,
      };

      tempTextBlocks[setIndex] = {
        ...tempTextBlocks[setIndex],
        order: index + 1,
      };

      setTempSection({ ...tempSection, text_blocks: tempTextBlocks });
      onSave({ ...tempSection, text_blocks: tempTextBlocks });
    }
  };

  const renderEditors = () => {
    if (isEmpty(textBlocks) && isEmpty(instance)) {
      return <></>;
    }

    const editors = [];
    textBlocks.forEach((textBlock, index) => {
      editors.push(
        <div key={textBlock.id}>
          {(isEmailPage || textBlock.type === 'raw' || textBlock.type === 'normal') && (
            <RichTextEditor
              params={props?.params}
              index={index}
              textBlockObj={textBlock}
              isEmailPage={isEmailPage}
              editorType={textBlock?.type}
              onHandleMove={onHandleMove}
              editorId={`toolbarEditor_${index}`}
              onUpdateTextBlock={(e) => onUpdateTextBlock(e, index)}
              onDragOver={(e) => handleOnDragEnterOver(e)}
              onDragEnter={(e) => handleOnDragEnterOver(e)}
              onHandleDeleteEditor={(e) => onHandleDeleteEditor(e)}
              countTextBlocks={textBlocks.length}
              style={{ backgroundColor: `${tempSection.sector_color}` }}
              features={['text_style', 'media', 'line_breaks', 'delete', 'reorder', 'offline', 'ai', 'history']}
            />
          )}
          {!isEmailPage && textBlock.type === 'ms' && (
            <RichTextEditor2
              params={props?.params}
              index={index}
              textBlockObj={textBlock}
              isEmailPage={isEmailPage}
              editorType={textBlock?.type}
              onHandleMove={onHandleMove}
              editorId={`toolbarEditor_${index}`}
              onUpdateTextBlock={(e) => onUpdateTextBlock(e, index)}
              onDragOver={(e) => handleOnDragEnterOver(e)}
              onDragEnter={(e) => handleOnDragEnterOver(e)}
              onHandleDeleteEditor={(e) => onHandleDeleteEditor(e)}
              countTextBlocks={textBlocks.length}
              style={{ backgroundColor: `${tempSection.sector_color}` }}
              features={['text_style', 'media', 'line_breaks', 'delete', 'reorder', 'offline', 'ai', 'history']}
            />
          )}
          {addTextBlockButtonRender(index + 2)}
        </div>,
      );
    });

    return (
      <DragDropComponent
        className="ContentType"
        keyName="text_block_ids"
        handle=".handle-sortable"
        returnData={editors}
        list={textBlocks}
        APICall={() => onSave(tempSection)}
        onSetList={(newState) => updateParams(newState)}
        setNewState={(newState) => updateParams(newState)}
      />
    );
  };

  const addTextBlockButtonRender = (index) => (
    <Row className="add-new-textblock text-center mt-3 mb-3">
      <Col>
        <CustomButton
          color="info"
          className="add-new-button button-xlg fw-normal me-2"
          title="NORMAL TEXTBLOCK"
          icon="fa fa-plus"
          onClick={() => onHandleAddNew('normal', index)}
        />
        {' '}
        <CustomButton
          color="info"
          className="add-new-button button-xlg fw-normal me-2"
          title="MULTI-STYLE TEXTBLOCK"
          icon="fa fa-plus"
          onClick={() => onHandleAddNew('ms', index)}
        />
        {' '}
        <CustomButton
          color="info"
          className="add-new-button button-lg fw-normal me-2"
          title="RAW TEXTBLOCK"
          icon="fa fa-plus"
          onClick={() => onHandleAddNew('raw', index)}
        />
      </Col>
    </Row>
  );

  if (isEmpty(tempSection)) {
    return <></>;
  }

  return (
    <div id={sectionId}>
      <AdvancedSettings
        siteId={siteId}
        instanceId={instanceId}
        isEmailPage={isEmailPage}
        section={tempSection}
        setSection={setTempSection}
        handleSaveBreakpoint={(e) => setTempSection({ ...tempSection, break_point: e })}
        onHandleSectionIsOffline={() => setTempSection({ ...tempSection, is_offline: !tempSection.is_offline })}
        onChangeInitialVisibility={() => setTempSection({ ...tempSection, initial_visibility: !tempSection.initial_visibility })}
        onHandleChangeVisibility={(e) => setTempSection({ ...tempSection, visible_to: e.value })}
      />

      {addTextBlockButtonRender(1)}
      {renderEditors()}
    </div>
  );
}

export default withRouter(ContentEditor);
