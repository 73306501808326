var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { replacePathParams, statsCenterRoutes, settingRoutes, contentCenterRoutes } from '../../constants/routes';
import { FormGroup, Label, Input, Card, CardBody, } from 'reactstrap';
import { addRecentItem } from '../../../../UtilsTS';
import { displaySuccess, displayError, selectColorStyles } from '../../../../Utils';
import { isEmpty } from 'lodash-es';
import Select from 'react-select';
import HeaderComponent from '../../components/common/header-component';
import { useParams } from 'react-router';
import withRouter from '../../helpers/withRouter';
import { useGetContentTopicQuery, useGetContentTopicBriefingQuery, useGetInstanceDataQuery, useGenerateContentFromContentTopicMutation, } from '../../api/apiSlice';
import CustomButton from '../../components/common/button';
import RadioButton from '../../components/common/radioButton';
import { getPageHierarchy } from '../../components/common/PageHierarchy';
import CarbonCopyEmail from '../../components/common/carbonCopyEmail';
import { useUser, useSite } from '../../hooks';
function GenerateDraftContent(props) {
    var _a, _b, _c, _d;
    const params = useParams();
    const { instanceId, siteId } = params;
    const { data: ct } = useGetContentTopicQuery({
        siteId: siteId || '',
        id: params.id || '',
    }, { skip: isEmpty(siteId) || isEmpty(params.id) });
    const { data: ctb } = useGetContentTopicBriefingQuery({
        siteId: siteId || '',
        id: (ct === null || ct === void 0 ? void 0 : ct.content_topic_briefing_id) || '',
    }, { skip: isEmpty(siteId) || isEmpty(ct === null || ct === void 0 ? void 0 : ct.content_topic_briefing_id) });
    const { data: instanceData } = useGetInstanceDataQuery(instanceId, { skip: isEmpty(instanceId) });
    const [generateContentFromContentTopic] = useGenerateContentFromContentTopicMutation();
    const user = useUser();
    const site = useSite();
    const [destination, setDestination] = useState('web-genius-page');
    const [parentId, setParentId] = useState(null);
    const [documentName, setDocumentName] = useState('');
    const [shareEmails, setShareEmails] = useState([]);
    useEffect(() => {
        if (ct === null || ct === void 0 ? void 0 : ct.name) {
            setDocumentName(ct === null || ct === void 0 ? void 0 : ct.name);
        }
    }, [ct]);
    useEffect(() => {
        if (user === null || user === void 0 ? void 0 : user.email) {
            setShareEmails([user.email]);
        }
    }, [user]);
    addRecentItem({
        instance_id: instanceId,
        site_id: siteId,
        type: 'content-center',
    });
    const staticBreadcrumbData = [
        {
            name: 'Dashboard',
            url: replacePathParams(settingRoutes.dashboard, [], props),
        },
        {
            name: 'Content Centre',
            url: replacePathParams(contentCenterRoutes.contentCenter, [], props),
        },
        {
            name: ctb === null || ctb === void 0 ? void 0 : ctb.business_name,
            url: replacePathParams(contentCenterRoutes.contentTopicBriefing, [{ key: 'id', value: ctb === null || ctb === void 0 ? void 0 : ctb.id }], props),
        },
        {
            name: ct === null || ct === void 0 ? void 0 : ct.name,
            url: replacePathParams(contentCenterRoutes.contentBriefingForm, [{ key: 'id', value: ct === null || ct === void 0 ? void 0 : ct.id }], props),
        },
    ];
    const pageData = ((_d = (_c = (_b = (_a = instanceData === null || instanceData === void 0 ? void 0 : instanceData.find((i) => i.id == instanceId)) === null || _a === void 0 ? void 0 : _a.sites) === null || _b === void 0 ? void 0 : _b.find((s) => s.id == siteId)) === null || _c === void 0 ? void 0 : _c.pages) === null || _d === void 0 ? void 0 : _d.filter((p) => p.parent_id || p.id === (site === null || site === void 0 ? void 0 : site.home_page_id))) || [];
    const pageOptionData = getPageHierarchy(pageData, '');
    const getSelectedOption = (options, selected) => options.filter((opt) => opt.value === selected)[0];
    return (_jsxs(_Fragment, { children: [_jsx(HeaderComponent, { setPath: {
                    headingName: 'Generate Draft Content',
                    addNewBtnName: '',
                    addNewPath: '',
                    backBtnName: 'Dashboard',
                    backToPath: statsCenterRoutes.contentCenter,
                    showBreadcrumb: false,
                    staticBreadcrumbData,
                } }), _jsx(Card, { className: "mt-3 mb-4 rounded", children: _jsxs(CardBody, { children: [_jsxs(FormGroup, { children: [_jsx(Label, { children: "Publish draft to" }), _jsxs("ul", { className: "custom-ul-layout ms-3", children: [_jsx("li", { children: _jsx(RadioButton, { title: "Web Genius Page", checked: destination === 'web-genius-page', onHandleChange: (e) => {
                                                    e.preventDefault();
                                                    setDestination('web-genius-page');
                                                } }) }), _jsx("li", { children: _jsx(RadioButton, { title: "Google Doc", checked: destination === 'google-doc', onHandleChange: (e) => {
                                                    e.preventDefault();
                                                    setDestination('google-doc');
                                                } }) })] })] }), destination === 'web-genius-page' && (_jsx(_Fragment, { children: _jsx(Select, { placeholder: "Select parent of new page", onChange: (e) => setParentId(e.value), options: pageOptionData, className: "mb-4", value: getSelectedOption(pageOptionData, parentId), styles: selectColorStyles }) })), destination === 'google-doc' && (_jsxs(_Fragment, { children: [_jsxs(FormGroup, { children: [_jsx(Label, { children: "Document name" }), _jsx(Input, { type: "text", value: documentName, onChange: (e) => setDocumentName(e.target.value) })] }), _jsxs(FormGroup, { children: [_jsx(Label, { children: "Share with" }), _jsx(CarbonCopyEmail, { selectedEmails: shareEmails, onChange: (e) => setShareEmails(e) })] })] })), _jsx(CustomButton, { color: "success", className: "common-success-button fw-bold", title: "PUBLISH DRAFT CONTENT", onClick: () => __awaiter(this, void 0, void 0, function* () {
                                const result = yield generateContentFromContentTopic({
                                    contentTopic: ct,
                                    destination,
                                    documentName,
                                    shareEmails,
                                });
                                if (result.data.meta.is_success)
                                    displaySuccess(result.data.meta.messages);
                                else
                                    displayError(result.data.meta.messages);
                            }) })] }) })] }));
}
export default withRouter(GenerateDraftContent);
