import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { get } from 'lodash-es';
import { DateTime } from 'luxon';
import DateTimeFormat from '../../components/common/DateTimeFormat';
import withRouter from '../../helpers/withRouter';
import CtaReport from './index';
import confirm from '../../components/common/confirm';
import { useDeleteCtaLogMutation } from '../../api/apiSlice';

function CtaReportNegativeReview(props) {
  const { instanceId } = props;

  const [orderBy, setOrderBy] = useState('created_at');
  const [orderDirection, setOrderDirection] = useState('desc');

  const [deleteCtaLog] = useDeleteCtaLogMutation();

  const onHandleDelete = async (id) => {
    const result = await confirm({
      title: <b>Confirm!</b>,
      message: 'Are you sure?',
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });

    if (result) {
      const params = {
        id,
        instance_id: instanceId,
      };
      await deleteCtaLog(params);
    }
  };

  const dataTableColumns = [
    {
      Header: (
        <Button
          onClick={() => {
            setOrderBy('created_at');
            setOrderDirection(orderDirection === 'desc' ? 'asc' : 'desc');
          }}
          className="custom-simple-txt-btn fw-bold"
        >
          <span>
            {'Date '}
            {orderBy === 'created_at' ? (
              <i
                className={` ${orderDirection === 'desc' ? 'fa fa-chevron-circle-up' : 'fa fa-chevron-circle-down'}`}
              />
            ) : ''}
          </span>
        </Button>
      ),
      id: 'Date',
      className: 'text-nowrap',
      disableSortBy: true,
      accessor: (d) => (
        <DateTimeFormat
          datetime={get(d, 'attributes.created_at', '')}
          localeString={DateTime.DATE_SHORT}
        />
      ),
    },
    {
      Header: 'Name',
      id: 'Name',
      disableSortBy: true,
      accessor: (d) => get(d, 'attributes.reviewer_name', ''),
    },
    {
      Header: 'Email',
      id: 'Email',
      disableSortBy: true,
      accessor: (d) => (
        <>
          <a href={`mailto:${get(d, 'attributes.reviewer_email', '')}`}>
            {get(d, 'attributes.reviewer_email', '')}
          </a>
        </>
      ),
    },
    {
      Header: 'Phone',
      id: 'Phone',
      disableSortBy: true,
      accessor: (d) => get(d, 'attributes.phone', ''),
    },
    {
      Header: 'Review',
      id: 'Review',
      disableSortBy: true,
      accessor: (d) => get(d, 'attributes.text', ''),
    },
    {
      Header: 'Action',
      id: 'action',
      className: 'text-center align-middle',
      disableSortBy: true,
      accessor: (d) => (
        <Button className="custom-simple-icon" color="danger" size="xs" onClick={() => onHandleDelete(d.id)}>
          <i className="fal fa-trash" />
        </Button>
      ),
    },
  ];

  return (
    <CtaReport
      instanceId={instanceId}
      dataTableColumns={dataTableColumns}
      orderBy={orderBy}
      orderDirection={orderDirection}
      ctaType="CTALogNegativeReview"
      typeName={['review', 'reviews']}
    />
  );
}

export default withRouter(CtaReportNegativeReview);
