import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { sectionObject, getContrast, getOppositeColor } from '../../../../../../Utils';
import { useSite } from '../../../../hooks';
import { useSelector } from 'react-redux';
import Color from 'color';
import { useAppSelector } from '../../../../../../rootStore';
// This is the preview of a section in the admin dashboard. It also tries to handle setting the background colour of
// the section if the text colours used in the section are not high enough contrast with the background, ie white text
// on a white background. It doesn't do this for component sections yet, we are rendering these with iframes so we will
// need to do this server-side.
function SectionPreview({ page, section, onClick, }) {
    const site = useSite();
    const ref = useRef(null);
    const textStyles = useSelector((s) => s.pageData.text_style);
    // This is only used for component sections
    const [previewId, setPreviewId] = useState(null);
    useEffect(() => {
        setPreviewId(crypto.randomUUID());
    }, [section]);
    const domain = useAppSelector((state) => state.pageData.admin_domain);
    const adminDomain = domain ? `https://${domain}` : '';
    useEffect(() => {
        if (!ref.current) {
            return;
        }
        if (!(section === null || section === void 0 ? void 0 : section.sector_color)) {
            return;
        }
        const rgbColors = [];
        function extractRGB(color) {
            var _a;
            if (color.match(/^-\d$/)) {
                // This is a preset color.
                const colorInt = parseInt(color, 10);
                if (site === null || site === void 0 ? void 0 : site.colors) {
                    const presetColor = site.colors.find((c) => c.id === Math.abs(colorInt));
                    // Use nullish coalescing to provide a default value for color if undefined
                    color = (_a = presetColor === null || presetColor === void 0 ? void 0 : presetColor.hex) !== null && _a !== void 0 ? _a : '';
                    // If color is still empty, return undefined
                    if (!color) {
                        return;
                    }
                }
                else {
                    return;
                }
            }
            let parsed;
            try {
                parsed = Color(color);
            }
            catch (_b) {
                return;
            }
            if (!parsed) {
                return;
            }
            const colorObj = parsed.object();
            return [colorObj.r, colorObj.g, colorObj.b];
        }
        function traverseNodes(node) {
            if (node instanceof Element) {
                [...node.classList].forEach((c) => {
                    const matches = c.match(/^style-([a-f0-9]{24})$/);
                    if (!matches) {
                        return;
                    }
                    const style = textStyles.find((s) => s.id === matches[1]);
                    if (!style || !style.color) {
                        return;
                    }
                    const rgb = extractRGB(style.color);
                    if (rgb) {
                        rgbColors.push(rgb);
                    }
                });
            }
            node.childNodes.forEach(traverseNodes);
        }
        traverseNodes(ref.current);
        // Calculate average color
        if (rgbColors.length === 0)
            return;
        const totalColors = rgbColors.reduce((acc, color) => {
            acc[0] += color[0];
            acc[1] += color[1];
            acc[2] += color[2];
            return acc;
        }, [0, 0, 0]);
        const avgColor = totalColors.map(value => Math.round(value / rgbColors.length));
        const contrast = getContrast(avgColor, section === null || section === void 0 ? void 0 : section.sector_color);
        if (contrast !== null && contrast < 1.35) {
            ref.current.style.backgroundColor = getOppositeColor(section === null || section === void 0 ? void 0 : section.sector_color);
        }
        else {
            ref.current.style.backgroundColor = section === null || section === void 0 ? void 0 : section.sector_color;
        }
    });
    if (section.type === 'SectionComponent') {
        if (previewId === null) {
            return null;
        }
        const previewUrl = `${adminDomain}/api/v1/components/${section === null || section === void 0 ? void 0 : section.component_id}/preview?unique_id=${`component-preview-${section === null || section === void 0 ? void 0 : section.id}`}&site_id=${site === null || site === void 0 ? void 0 : site.id}&section_id=${section === null || section === void 0 ? void 0 : section.id}&scss_vars=${(section === null || section === void 0 ? void 0 : section.scss_vars) ? encodeURIComponent(JSON.stringify(section === null || section === void 0 ? void 0 : section.scss_vars)) : ''}&html_vars=${(section === null || section === void 0 ? void 0 : section.html_vars) ? encodeURIComponent(JSON.stringify(section === null || section === void 0 ? void 0 : section.html_vars)) : ''}`;
        return (_jsx("section", { className: `SectionComponent bp-${section.id}`, children: _jsx("iframe", { id: `component-preview-${section.id}`, src: previewUrl, className: "w-100" }) }));
    }
    return (_jsx("div", { ref: ref, style: { backgroundColor: section === null || section === void 0 ? void 0 : section.sector_color }, children: sectionObject(page, section, onClick) }));
}
export default SectionPreview;
