import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  FormGroup,
} from 'reactstrap';
import HeaderComponent from '../../../components/common/header-component';
import {
  dataCenter, settingRoutes, replacePathParams, mainRoutes,
} from '../../../constants/routes';
import Pagination from '../../../components/common/custom-pagination';
import Select from 'react-select';
import {
  displayError, displaySuccess, getDataFieldOptions, getSelectedOption, getSpecialFieldIdByName, selectColorStyles,
} from '../../../../../Utils';
import { useParams } from 'react-router-dom';
import { isEmpty, map } from 'lodash-es';
import withRouter from '../../../helpers/withRouter';
import MemberCard from './MemberCard';
import DataFieldSelectBox from './DataFieldSelectBox';
import CustomSelect from '../../../components/common/custom-select';
import confirm from '../../../components/common/confirm';
import {
  useCreateSavedSearchMutation,
  useDeleteSearchMemberMutation,
  useEmailSeriesSubscribeMemberMutation,
  useExportMembersQuery,
  useGetDatabaseFieldsQuery,
  useGetEmailSeriesQuery,
  useGetMembersQuery,
  useGetMemberSearchByIdQuery,
} from '../../../api/apiSlice';
import { addRecentItem } from '../../../../../UtilsTS';

function Members(props) {
  const { navigate } = props;
  const { searchId, pageNo, instanceId } = useParams();
  const [sortByOptions] = useState([{ value: 'asc', label: 'A to Z' }, { value: 'desc', label: 'Z to A' }]);
  const [orderBy, setOrderBy] = useState('asc');
  const [orderByField, setOrderByField] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [modal, setModal] = useState(false);
  const [selectedEmailSeries, setSelectedEmailSeries] = useState({});
  const [subscribeMemberModal, setSubscribeMemberModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [confirmDelete, setConfirmDelete] = useState('');
  const isAllMembers = !searchId;
  const [exportType, setExportType] = useState('');

  const { data } = useGetDatabaseFieldsQuery({ instanceId }, { skip: isEmpty(instanceId) });
  const { data: emailSeries = [] } = useGetEmailSeriesQuery(instanceId, { skip: isEmpty(instanceId) });
  const [subscribeMembersToEmailSeries] = useEmailSeriesSubscribeMemberMutation();
  const [createSavedSearch] = useCreateSavedSearchMutation();
  const [deleteMembers] = useDeleteSearchMemberMutation();

  const dataFieldSelectOptions = data || [];

  const { isSuccess } = useExportMembersQuery(
    { id: searchId ? searchId : '-', type: exportType, instance_id: instanceId },
    { skip: !exportType }
  );

  const sendParams = {
    instance_id: instanceId,
    page: pageNo,
    order_by: orderByField || getSpecialFieldIdByName(getDataFieldOptions(dataFieldSelectOptions), 'Join Date'),
    order_direction: orderBy,
    page_size: pageSize,
  };

  const { data: membersData } = useGetMembersQuery(
    sendParams,
    { skip: isEmpty(instanceId) || !isAllMembers || isEmpty(dataFieldSelectOptions) }
  );
  const { data: searches } = useGetMemberSearchByIdQuery(
    { ...sendParams, id: searchId },
    { skip: isEmpty(instanceId) || isEmpty(searchId) || isEmpty(dataFieldSelectOptions) }
  );

  const members = membersData?.members || searches?.members || [];
  const pagination = membersData?.pagination || searches?.pagination || {};

  if (instanceId) {
    addRecentItem({
      instance_id: instanceId,
      type: isAllMembers ? 'all-members' : 'search-results',
    });
  }

  useEffect(() => {
    if (isSuccess) {
      displaySuccess('Member export has started and you will receive an email when it is completed.', 7000);
    }
  }, [isSuccess]);

  /* On handle sort by field name change */
  const onHandleSortByField = (e) => {
    const { value } = e;
    setOrderByField(value);
  };

  /* On handle sort by change */
  const onHandleSortBy = (e) => {
    const { value } = e;
    setOrderBy(value);
  };

  /* On change page */
  const onChangePage = (e) => {
    if (!isAllMembers) {
      navigate(
        replacePathParams(
          dataCenter.showMemberSearch,
          [
            { key: 'searchId', value: searchId },
            { key: 'pageNo', value: e },
          ],
          props,
        ),
      );
    } else {
      navigate(
        replacePathParams(
          dataCenter.allMembers,
          [
            { key: 'pageNo', value: e },
          ],
          props,
        ),
      );
    }
  };

  /* on change per page value */
  const changePageSize = (size) => {
    setPageSize(size);
  };

  /* On handle modal toggle */
  const toggle = () => setModal(!modal);

  /* On handle change search name */
  const onSearchNameChange = (e) => {
    const { value } = e.target;
    setSearchName(value);
  };

  /* On handle save this found */
  const saveThisFound = async () => {
    const sendParams = {
      id: searchId,
      name: searchName,
      instance_id: instanceId,
    };
    if (searchName === '') {
      displayError('Please enter search name!');
    } else {
      const response = await createSavedSearch(sendParams);
      if (response && response?.data?.meta?.is_success) {
        displaySuccess(response?.data?.meta?.messages);
        setModal(!modal);
      }
    }
  };

  const onHandleSubscribeEmailSeries = async () => {
    if (isEmpty(selectedEmailSeries.value)) {
      displayError('Please select an email series.');
      return;
    }

    const result = await confirm({
      title: <b>Confirm!</b>,
      message: `Are you sure you want to subscribe these members to the email series ${selectedEmailSeries.label}?`,
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });

    if (result) {
      const res = await subscribeMembersToEmailSeries({
        id: searchId || '-',
        instance_id: instanceId,
        email_series_id: selectedEmailSeries.value,
      });
      if (res && res?.data?.meta?.is_success) {
        setSubscribeMemberModal(false);
        displaySuccess(res?.data?.meta?.messages);
      }
    }
  };

  const deleteAllMembers = async () => {
    if (confirmDelete !== 'DELETE') {
      displayError('Please type DELETE to confirm!');
    } else {
      const response = await deleteMembers(isAllMembers ? '-' : searchId);
      if (response && response?.data?.meta?.is_success) {
        setDeleteModal(false);
        setConfirmDelete('');
        displaySuccess(response?.data?.meta?.messages);
      }
    }
  };

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Data Centre',
      url: replacePathParams(mainRoutes.dataCenter, [], props),
    },
    { name: 'Member Search', url: '' },
  ];

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Search Results',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        backToPath: replacePathParams(dataCenter.memberSearch, [], props),
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      {pagination && pagination?.count
        ? (
          <Pagination
            type={['member', 'members']}
            currentPage={Number(pageNo)}
            totalRecord={pagination.count}
            perPage={pagination.per_page}
            totalPages={pagination.pages}
            records={members}
            onChangePage={onChangePage}
            getPagination={pagination}
            pageSize={pageSize}
            changePageSize={changePageSize}
          />
        )
        : ''}
      <Row>
        <Col lg={9} md={8}>
          <MemberCard members={members} instanceId={instanceId} />
        </Col>
        <Col lg={3} md={4}>
          <div className="custom-right-sidebar-box">
            <div className="custom-sidebar-bg mb-3">
              <p><b>Sort by</b></p>
              <DataFieldSelectBox
                defaultValue
                className="mb-3"
                instanceId={instanceId}
                placeholder="Field name"
                selectedId={orderByField}
                onChange={onHandleSortByField}
              />
              <Select
                value={getSelectedOption(sortByOptions, orderBy)}
                options={sortByOptions}
                onChange={onHandleSortBy}
                isDisabled={!orderByField}
                styles={selectColorStyles}
              />
            </div>
          </div>
          <div className="custom-right-sidebar-box">
            <div className="custom-sidebar-bg mb-3">
              <p><b>Options</b></p>
              {!isAllMembers && (
                <Button onClick={toggle} className="custom-simple-txt-btn mb-3">
                  <i className="fal fa-folder me-2" />
                  Save this found set
                </Button>
              )}
              <Button onClick={() => setExportType('download_csv')} className="custom-simple-txt-btn mb-3 d-block">
                <i className="fal fa-download me-2"></i>
                Export as CSV
              </Button>
              <Button onClick={() => setExportType('google_drive')} className="custom-simple-txt-btn mb-3 d-block">
                <i className="fal fa-cloud-download-alt me-2"></i>
                Export to Google Drive
              </Button>
              <Button onClick={() => setSubscribeMemberModal(true)} className="custom-simple-txt-btn mb-3">
                <i className="fal fa-envelopes-bulk me-2" />
                Subscribe these members to email series
              </Button>
              <Button onClick={() => setDeleteModal(true)} className="custom-simple-txt-btn mb-3">
                <i className="fa-light fa-user-xmark me-2"></i>
                Delete these members
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Save Search</ModalHeader>
        <ModalBody>
          <Input
            placeholder="Search name"
            value={searchName}
            onChange={onSearchNameChange}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggle}><b>Close</b></Button>
          {' '}
          <Button color="success" onClick={saveThisFound}><b>Save</b></Button>
        </ModalFooter>
      </Modal>
      {subscribeMemberModal && (
        <Modal isOpen={subscribeMemberModal} toggle={() => setSubscribeMemberModal(false)}>
          <ModalHeader toggle={toggle}>Subscribe all members to email series</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>
                Email Series
              </Label>
              <CustomSelect
                options={map(emailSeries, (item) => ({ label: item.name, value: item.id }))}
                onChange={(e) => setSelectedEmailSeries(e)}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => setSubscribeMemberModal(false)}><b>Cancel</b></Button>
            {' '}
            <Button color="success" onClick={onHandleSubscribeEmailSeries}><b>Subscribe</b></Button>
          </ModalFooter>
        </Modal>
      )}
      {deleteModal && (
        <Modal isOpen={deleteModal} toggle={() => setDeleteModal(!deleteModal)} autoFocus={false}>
          <ModalHeader toggle={() => setDeleteModal(!deleteModal)}>
            Are you sure you want to delete all of these members?
          </ModalHeader>
          <ModalBody>
            <Label className="w-100">
              Type &quot;DELETE&quot; to confirm:
              <Input
                autoFocus
                value={confirmDelete}
                onChange={(e) => setConfirmDelete(e.target.value)}
              />
            </Label>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => setDeleteModal(!deleteModal)}><b>CANCEL</b></Button>
            {' '}
            <Button color="success" onClick={() => deleteAllMembers()}><b>Save</b></Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
}

export default withRouter(Members);
