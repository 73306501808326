'use strict';

import React, { useEffect, useState } from 'react';
import { Button, Input, InputGroup, InputGroupText } from 'reactstrap';
import { ReactCSS } from 'reactcss';
import { map, isEmpty } from 'lodash-es';
import SketchPicker from './WGSketch';
import './index.scss';
import { getColorVal } from '../breakpoint';
import withRouter from '../../../helpers/withRouter';
import { getContrast, rgbaToHex } from '../../../../../Utils';
import { useSite } from '../../../hooks';

function ColorPicker(props) {
  const { isEmailPage = false, isDisabled, onChange, color, onDeleteValue, isColourPalette } = props;

  const site = useSite();

  const presetColors = site?.colors || [];

  const [displayColorPicker, setColorPicker] = useState(false);

  const getColor = getColorVal(color, presetColors);
  const isGradient = getColor && (getColor.includes('linear') || getColor.includes('radial'));

  const getPreset = (val) => {
    return presetColors.find((e) => e.hex === val || e.hex.substr(0, 7) === val);
  };

  useEffect(() => {
    if ((!isGradient || !isColourPalette) && !isEmpty(presetColors)) {
      addTickSelectedPreset();
    }
  }, [color, isGradient, isColourPalette, presetColors]);

  const onOutsideClick = (e) => {
    if (!e.target.closest('.WGColorPicker')) {
      setColorPicker(false);
    }
  };

  const addTickSelectedPreset = () => {
    setTimeout(() => {
      const pickerOuter = document.getElementById('rbgcp-wrapper');
      if (pickerOuter) {
        const elements = pickerOuter.lastChild.childNodes?.[1];
        elements.childNodes.forEach(e => e.classList.remove('selected-color-preset'));
        elements.childNodes.forEach(e => e.classList.remove('selected-color-preset-high-contrast'));
        const preset = getPreset(getColor);
        if (preset) {
          const contrast = getContrast(preset.hex, 'white');
          elements.childNodes?.[preset?.id - 1]?.classList?.add(contrast < 1.35 ? 'selected-color-preset-high-contrast' : 'selected-color-preset');
        }
      }
    }, 100);
  };

  useEffect(() => {
    window.addEventListener('click', onOutsideClick);

    return () => {
      window.removeEventListener('click', onOutsideClick);
    };
  }, []);

  const handleClick = () => {
    setColorPicker(!displayColorPicker);
    addTickSelectedPreset();
  };

  const handleChange = (colorObject) => {
    const { rgbaArr, getGradientObject, isGradient } = colorObject;
    const hexString = rgbaToHex({ r: rgbaArr[0], g: rgbaArr[1], b: rgbaArr[2], a: rgbaArr[3] });
    let value = '';

    if (typeof getGradientObject === 'function' && isGradient) {
      const { gradientType, degrees, colors: gradientColors } = getGradientObject();
      let str = '';
      gradientColors.forEach((color, i) => {
        str += `${color?.value} ${color?.left}%${i === gradientColors.length - 1 ? '' : ', '}`;
      });
      value = `${gradientType}(${degrees}, ${str})`;
    } else {
      const preset = getPreset(hexString);
      value = preset && !isColourPalette ? -Math.abs(preset?.id) : hexString;
    }
    onChange({ target: { value, ...colorObject } });
  };

  const styles = ReactCSS({
    default: {
      swatch: {
        background: getColor,
        cursor: 'pointer',
        height: '45px',
      },
      popover: {
        position: 'absolute',
        zIndex: '1060',
        top: '43px',
      },
    },
  });

  return (
    <>
      <InputGroup className="WGColorPicker">
        <Input className="form-control" style={styles.swatch} onClick={handleClick} disabled={isDisabled} />
        {displayColorPicker
          ? (
              <div style={styles.popover}>
                <SketchPicker
                  {...props}
                  color={getColor}
                  isGradient={isGradient}
                  onChange={handleChange}
                  isEmailPage={isEmailPage}
                  presetColors={map(presetColors, (item) => item.hex)}
                />
              </div>
            )
          : null}
        <InputGroupText>{isGradient ? '' : getColor}</InputGroupText>
        <Button color="info" className="input-group-text" onClick={onDeleteValue} disabled={isDisabled}>&times;</Button>
      </InputGroup>
    </>
  );
}

export default withRouter(ColorPicker);
