var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, Fragment, useRef } from 'react';
import { replacePathParams, statsCenterRoutes, settingRoutes, contentCenterRoutes } from '../../constants/routes';
import { Row, Col, FormGroup, Label, Input, Card, CardBody, CardTitle, } from 'reactstrap';
import { addRecentItem } from '../../../../UtilsTS';
import { cloneDeep, isEmpty } from 'lodash-es';
import HeaderComponent from '../../components/common/header-component';
import confirm from '../../components/common/confirm';
import { useParams } from 'react-router';
import withRouter from '../../helpers/withRouter';
import { useGetContentTopicQuery, useGetContentTopicBriefingQuery, useUpdateContentTopicMutation, } from '../../api/apiSlice';
import { default as ContentTopic } from '../../models/ContentTopic';
import BottomActionToolbar from '../../components/common/toolbar';
import CustomButton from '../../components/common/button';
import SaveAndContinue from '../../components/common/saveAndContinue';
// Automatically set textarea height depending on the number of lines.
const autoHeightTextArea = (e) => {
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`;
};
const AutoHeightTextArea = ({ name, value, onChange, style }) => (_jsx(Input, { type: "textarea", name: name, value: value, onChange: onChange, style: style }));
function ContentBriefingForm(props) {
    var _a;
    const { navigate } = props;
    const params = useParams();
    const { instanceId, siteId } = params;
    const { data: ct } = useGetContentTopicQuery({
        siteId: siteId || '',
        id: params.id || '',
    }, { skip: isEmpty(siteId) || isEmpty(params.id) });
    const { data: ctb } = useGetContentTopicBriefingQuery({
        siteId: siteId || '',
        id: (ct === null || ct === void 0 ? void 0 : ct.content_topic_briefing_id) || '',
    }, { skip: isEmpty(siteId) || isEmpty(ct === null || ct === void 0 ? void 0 : ct.content_topic_briefing_id) });
    const [editingCt, setEditingCt] = useState(ct);
    const [updateContentTopic] = useUpdateContentTopicMutation();
    const ref = useRef(null);
    useEffect(() => {
        if (ct) {
            setEditingCt(ct);
        }
    }, [ct]);
    useEffect(() => {
        if (ref.current) {
            ref.current.querySelectorAll('textarea').forEach((t) => {
                autoHeightTextArea({ target: t });
            });
        }
    }, [editingCt]);
    addRecentItem({
        instance_id: instanceId,
        site_id: siteId,
        type: 'content-center',
    });
    const onChange = (e) => {
        const { name, value } = e.target;
        setEditingCt((prev) => new ContentTopic(Object.assign(Object.assign({}, prev), { [name]: value })));
    };
    const update = () => __awaiter(this, void 0, void 0, function* () {
        if (!editingCt) {
            return false;
        }
        const result = yield updateContentTopic(editingCt);
        if (result) {
            return true;
        }
    });
    const staticBreadcrumbData = [
        {
            name: 'Dashboard',
            url: replacePathParams(settingRoutes.dashboard, [], props),
        },
        {
            name: 'Content Centre',
            url: replacePathParams(contentCenterRoutes.contentCenter, [], props),
        },
        {
            name: (ctb === null || ctb === void 0 ? void 0 : ctb.business_name) || '',
            url: replacePathParams(contentCenterRoutes.contentTopicBriefing, [{ key: 'id', value: ctb === null || ctb === void 0 ? void 0 : ctb.id }], props),
        },
        { name: 'Content Brief Form', url: '' },
    ];
    if (!ctb || !editingCt) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(HeaderComponent, { setPath: {
                    headingName: 'Content Brief Form',
                    addNewBtnName: '',
                    addNewPath: '',
                    backBtnName: 'Dashboard',
                    backToPath: statsCenterRoutes.contentCenter,
                    showBreadcrumb: false,
                    staticBreadcrumbData,
                } }), _jsx(Card, { className: "mt-3 mb-4 rounded", innerRef: ref, children: _jsxs(CardBody, { children: [_jsxs(CardTitle, { children: [editingCt.name, _jsx("br", {}), _jsx("br", {}), "Type:", ' ', editingCt === null || editingCt === void 0 ? void 0 : editingCt.typeText(), ' Page'] }), _jsx(Row, { children: _jsx(Col, { children: _jsxs(FormGroup, { children: [_jsx(Label, { children: "Topic" }), _jsx(Input, { name: "name", value: editingCt.name || '', onChange: onChange })] }) }) }), editingCt.type === 'article' && (_jsxs(_Fragment, { children: [(_a = editingCt.sub_topics) === null || _a === void 0 ? void 0 : _a.map((st, i) => {
                                    var _a;
                                    return (_jsxs(Fragment, { children: [_jsx(Row, { children: _jsx(Col, { children: _jsxs(FormGroup, { children: [_jsxs(Label, { children: ["Sub Topic", ' ', i + 1] }), _jsx(Input, { name: "name", value: st.name || '', onChange: (e) => {
                                                                    const tempSubTopics = [...editingCt.sub_topics || []];
                                                                    tempSubTopics[i] = Object.assign(Object.assign({}, st), { name: e.target.value });
                                                                    setEditingCt((prev) => new ContentTopic(Object.assign(Object.assign({}, prev), { sub_topics: tempSubTopics })));
                                                                } })] }) }) }), (_a = st.questions) === null || _a === void 0 ? void 0 : _a.map((q, j) => (_jsxs(Fragment, { children: [_jsx(Row, { className: "ms-4", children: _jsx(Col, { children: _jsxs(FormGroup, { children: [_jsxs(Label, { children: ["Question", ' ', j + 1, ' - ', _jsx("a", { href: "#", onClick: (e) => __awaiter(this, void 0, void 0, function* () {
                                                                                    e.preventDefault();
                                                                                    if (q.name || q.answer) {
                                                                                        const result = yield confirm({
                                                                                            title: _jsx("b", { children: "Confirm!" }),
                                                                                            message: 'Are you sure you want to delete this question?',
                                                                                            confirmText: 'Yes',
                                                                                            confirmColor: 'success',
                                                                                            cancelColor: 'btn btn-danger',
                                                                                            cancelText: 'No',
                                                                                        });
                                                                                        if (!result)
                                                                                            return;
                                                                                    }
                                                                                    const tempSubTopics = cloneDeep(editingCt.sub_topics || []);
                                                                                    if (!tempSubTopics[i].questions)
                                                                                        return;
                                                                                    tempSubTopics[i].questions.splice(j, 1);
                                                                                    setEditingCt((p) => new ContentTopic(Object.assign(Object.assign({}, p), { sub_topics: tempSubTopics })));
                                                                                }), children: "Delete" })] }), _jsx(Input, { name: "name", value: q.name || '', onChange: (e) => {
                                                                            const tempSubTopics = cloneDeep(editingCt.sub_topics || []);
                                                                            if (!tempSubTopics[i].questions)
                                                                                return;
                                                                            tempSubTopics[i].questions[j].name = e.target.value;
                                                                            setEditingCt((p) => new ContentTopic(Object.assign(Object.assign({}, p), { sub_topics: tempSubTopics })));
                                                                        } })] }) }) }), _jsx(Row, { className: "ms-4", children: _jsx(Col, { children: _jsxs(FormGroup, { children: [_jsx(Label, { children: "Answer" }), _jsx(AutoHeightTextArea, { name: "answer", value: q.answer || '', onChange: (e) => {
                                                                            const tempSubTopics = cloneDeep(editingCt.sub_topics || []);
                                                                            if (!tempSubTopics[i].questions)
                                                                                return;
                                                                            tempSubTopics[i].questions[j].answer = e.target.value;
                                                                            setEditingCt((p) => new ContentTopic(Object.assign(Object.assign({}, p), { sub_topics: tempSubTopics })));
                                                                        } })] }) }) })] }, j))), _jsx(Row, { className: "ms-4 mb-2", children: _jsx(Col, { children: _jsx(CustomButton, { color: "success", className: "common-success-button fw-bold", title: "ADD QUESTION", onClick: () => {
                                                            const tempSubTopics = cloneDeep(editingCt.sub_topics || []);
                                                            if (!tempSubTopics[i].questions)
                                                                return;
                                                            tempSubTopics[i].questions.push({ name: '', answer: '' });
                                                            setEditingCt((p) => new ContentTopic(Object.assign(Object.assign({}, p), { sub_topics: tempSubTopics })));
                                                        } }) }) }), _jsx(Row, { className: "ms-4", children: _jsx(Col, { children: _jsxs(FormGroup, { children: [_jsx(Label, { children: "Targeted keywords (to be finalised after completion of Keyword Research)" }), _jsx(AutoHeightTextArea, { name: "name", value: st.keywords || '', onChange: (e) => {
                                                                    const tempSubTopics = [...editingCt.sub_topics || []];
                                                                    tempSubTopics[i] = Object.assign(Object.assign({}, st), { keywords: e.target.value });
                                                                    setEditingCt((prev) => new ContentTopic(Object.assign(Object.assign({}, prev), { sub_topics: tempSubTopics })));
                                                                } })] }) }) })] }, i));
                                }), _jsx(Row, { children: _jsx(Col, { children: _jsxs(FormGroup, { children: [_jsx(Label, { children: "Primary keywords" }), _jsx(Input, { name: "primary_keywords", value: editingCt.primary_keywords || '', onChange: onChange })] }) }) }), _jsx(Row, { children: _jsx(Col, { children: _jsxs(FormGroup, { children: [_jsx(Label, { children: "Secondary keywords" }), _jsx(Input, { name: "secondary_keywords", value: editingCt.secondary_keywords || '', onChange: onChange })] }) }) }), _jsx(Row, { children: _jsx(Col, { children: _jsxs(FormGroup, { children: [_jsx(Label, { children: "Related keywords" }), _jsx(Input, { name: "related_keywords", value: editingCt.related_keywords || '', onChange: onChange })] }) }) }), _jsx(CustomButton, { color: "success", className: "common-success-button fw-bold", title: "ADD SUB-TOPIC", onClick: () => {
                                        const tempSubTopics = cloneDeep(editingCt.sub_topics || []);
                                        tempSubTopics.push({ name: '', questions: [{ name: '', answer: '' }, { name: '', answer: '' }] });
                                        setEditingCt((p) => new ContentTopic(Object.assign(Object.assign({}, p), { sub_topics: tempSubTopics })));
                                    } })] })), editingCt.type === 'product_service' && (_jsx(_Fragment, { children: "ASAP Form from Dominator Brief goes here" }))] }) }), _jsx(BottomActionToolbar, { component: (_jsxs(_Fragment, { children: [_jsx(SaveAndContinue, { onSave: update, onContinue: () => {
                                navigate(replacePathParams(contentCenterRoutes.contentTopicBriefing, [{ key: 'id', value: ctb === null || ctb === void 0 ? void 0 : ctb.id }], props));
                            } }), ' ', _jsx(CustomButton, { color: "success", className: "common-success-button fw-bold", title: "GENERATE DRAFT PAGE CONTENT", onClick: () => __awaiter(this, void 0, void 0, function* () {
                                const result = yield update();
                                if (result) {
                                    navigate(replacePathParams(contentCenterRoutes.generateDraftContent, [{ key: 'id', value: ct === null || ct === void 0 ? void 0 : ct.id }], props));
                                }
                            }) })] })) })] }));
}
export default withRouter(ContentBriefingForm);
