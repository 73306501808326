var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { replacePathParams, statsCenterRoutes, settingRoutes, contentCenterRoutes } from '../../constants/routes';
import { Card, CardBody, CardTitle, Table, Button, } from 'reactstrap';
import { addRecentItem } from '../../../../UtilsTS';
import { isEmpty } from 'lodash-es';
import HeaderComponent from '../../components/common/header-component';
import confirm from '../../components/common/confirm';
import { useParams } from 'react-router';
import withRouter from '../../helpers/withRouter';
import { useCreateContentTopicBriefingMutation, useGetContentTopicBriefingsQuery, useDeleteContentTopicBriefingMutation, } from '../../api/apiSlice';
import ContentTopicBriefing from '../../models/ContentTopicBriefing';
import BottomActionToolbar from '../../components/common/toolbar';
import CustomButton from '../../components/common/button';
import { dateTimeString } from '../../components/common/DateTimeFormat';
function ContentCentre(props) {
    const { navigate } = props;
    const params = useParams();
    const { instanceId, siteId } = params;
    const { data: ctbs } = useGetContentTopicBriefingsQuery({ siteId: siteId || '' }, { skip: isEmpty(siteId) });
    const [createContentTopicBriefing] = useCreateContentTopicBriefingMutation();
    const [deleteContentTopicBriefing] = useDeleteContentTopicBriefingMutation();
    addRecentItem({
        instance_id: instanceId,
        site_id: siteId,
        type: 'content-center',
    });
    const addContentTopicBriefing = () => __awaiter(this, void 0, void 0, function* () {
        const result = yield createContentTopicBriefing(new ContentTopicBriefing({ site_id: siteId }));
        onEdit(result.data.data);
    });
    const onEdit = (ctb) => {
        const url = replacePathParams(contentCenterRoutes.contentTopicBriefing, [
            {
                key: 'id',
                value: ctb.id,
            },
        ], props);
        navigate(url);
    };
    const onDelete = (ctb) => __awaiter(this, void 0, void 0, function* () {
        const result = yield confirm({
            title: _jsx("b", { children: "Confirm!" }),
            message: 'Are you sure?',
            confirmText: 'Yes',
            confirmColor: 'success',
            cancelColor: 'btn btn-danger',
            cancelText: 'No',
        });
        if (result) {
            deleteContentTopicBriefing(ctb);
        }
    });
    const staticBreadcrumbData = [
        {
            name: 'Dashboard',
            url: replacePathParams(settingRoutes.dashboard, [], props),
        },
        { name: 'Content Centre', url: '' },
    ];
    if (!ctbs) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(HeaderComponent, { setPath: {
                    headingName: 'Content Centre',
                    addNewBtnName: '',
                    addNewPath: '',
                    backBtnName: 'Dashboard',
                    backToPath: statsCenterRoutes.contentCenter,
                    showBreadcrumb: false,
                    staticBreadcrumbData,
                } }), _jsx(Card, { className: "mt-3 mb-4 rounded", children: _jsxs(CardBody, { children: [_jsx(CardTitle, { className: "mb-4", children: "Content Topic Briefings" }), _jsxs(Table, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Business Name" }), _jsx("th", { children: "Created" }), _jsx("th", { children: "Updated" }), _jsx("th", {})] }) }), _jsx("tbody", { children: ctbs.map((ctb) => (_jsxs("tr", { children: [_jsx("td", { children: ctb.business_name }), _jsx("td", { children: dateTimeString(ctb.created_at) }), _jsx("td", { children: dateTimeString(ctb.updated_at) }), _jsxs("td", { children: [_jsx(Button, { className: "custom-simple-icon", color: "primary", size: "xs", onClick: () => onEdit(ctb), children: _jsx("i", { className: "fal fa-pen" }) }), ' ', _jsx(Button, { className: "custom-simple-icon", color: "danger", size: "xs", onClick: () => onDelete(ctb), children: _jsx("i", { className: "fal fa-trash" }) })] })] }, ctb.id))) })] })] }) }), _jsx(BottomActionToolbar, { component: (_jsx(CustomButton, { color: "success", className: "common-success-button button-md fw-bold", title: "ADD", onClick: () => addContentTopicBriefing() })) })] }));
}
export default withRouter(ContentCentre);
