/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Input, FormGroup, Label, Row, Col, Card,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import { displayError, selectColorStyles, getArrIndex, specialFieldsArr, displaySuccess } from '../../../../Utils';
import { addRecentItem } from 'UtilsTS';
import { mainRoutes, settingRoutes, replacePathParams, dataCenter } from '../../constants/routes';
import CustomSelect from '../../components/common/custom-select';
import SaveAndContinue from '../../components/common/saveAndContinue';
import { useParams } from 'react-router-dom';
import HeaderComponent from '../../components/common/header-component';
import { get, isEmpty } from 'lodash-es';
import BottomActionToolbar from '../../components/common/toolbar';
import withRouter from '../../helpers/withRouter';
import { useCreateMemberMutation, useGetDatabaseFieldsQuery, useGetMemberQuery, useUpdateMemberMutation } from '../../api/apiSlice';

function DatabaseFields(props) {
  const { instanceId, memberId } = useParams();
  const [dbFields, setDbFields] = useState([]);
  const [dbFieldsValue, setDbFieldsValue] = useState([]);
  const getFieldIds = (arr) => arr.map((items) => ({ key: items.id, value: items?.attributes?.values || [] }));

  const { data: fieldsData = [] } = useGetDatabaseFieldsQuery({ instanceId }, { skip: isEmpty(instanceId) && !isEmpty(memberId) });

  const [createMember] = useCreateMemberMutation();
  const [updateMember] = useUpdateMemberMutation();
  const { data: memberData } = useGetMemberQuery({ instance_id: instanceId, id: memberId }, { skip: isEmpty(instanceId) || isEmpty(memberId) });

  const navigate = useNavigate();

  useEffect(() => {
    if (memberId && memberData) {
      const getData = memberData.attributes.db_field.map((item) => (
        {
          id: item.id,
          type: memberData.type,
          attributes: {
            field_name: item.field_name,
            field_type: item.field_type,
            position: item.position,
            values: item.value,
            short_name: item.short_name,
            db_field_values: item.db_field_values,
            default: item.default,
            is_special: item.is_special,
            is_editable: item.is_editable,
          },
        }
      ));
      setDbFields(getData);
      setDbFieldsValue(getFieldIds(getData));
    } else {
      setDbFields(fieldsData);
      setDbFieldsValue(getFieldIds(fieldsData));
    }
  }, [memberId, memberData, fieldsData]);

  const onUpdateStateData = (id, stateData) => {
    const tempState = [...dbFieldsValue];
    const getIndex = getArrIndex(tempState, 'key', id);
    tempState[getIndex] = { key: id, value: [stateData] };
    setDbFieldsValue(tempState);
  };

  const onDateSelect = (e, id) => {
    onUpdateStateData(id, e);
  };

  const onHandleChangeValue = (e) => {
    const { value, id } = e.target;
    onUpdateStateData(id, value);
  };

  const onDropdownChange = (e, id) => {
    const { value } = e;
    onUpdateStateData(id, value);
  };

  const defaultselectedOption = (options, selected) => options.filter((opt) => {
    if (selected !== null) {
      return (opt.value === selected[0] ? opt : null);
    }
    return null;
  });

  const selectBoxOption = (id, options, selectedOption, isDisabled) => {
    const optionData = options.map((opt) => ({ label: opt, value: opt }));
    return (
      <CustomSelect
        isDisabled={isDisabled}
        value={defaultselectedOption(optionData, selectedOption)}
        options={optionData}
        onChange={(e) => onDropdownChange(e, id)}
        styles={selectColorStyles}
      />
    );
  };

  const onCheckboxChecked = (e, id) => {
    const { checked, value } = e.target;
    const tempState = [...dbFieldsValue];
    const getIndex = getArrIndex(tempState, 'key', id);
    const tempChecked = { ...tempState[getIndex] };
    if (checked) {
      tempChecked.value.push(value);
      tempState[getIndex] = tempChecked;
      setDbFieldsValue(tempState);
    } else {
      const updatedCheckedData = tempChecked.value.filter((items) => items !== value);
      tempState[getIndex].value = updatedCheckedData;
      setDbFieldsValue(tempState);
    }
  };

  const onDefaultChecked = (checkList, checked) => {
    const returnData = checked.includes(checkList);
    return returnData;
  };

  const fieldType = (id, name, type, value, requiredValue, dbFieldValue, isDisabled) => {
    const getValues = !isEmpty(dbFieldValue) ? dbFieldValue : value;
    const getIndex = getArrIndex(dbFieldsValue, 'key', id);
    const fieldValue = get(dbFieldsValue[getIndex], 'value', '');
    const disabledField = isDisabled ? 'disabled-field' : '';

    switch (type) {
      case 'short_text':
        return (
          <FormGroup>
            <Label>
              {name}
            </Label>
            {requiredValue ? <span className="text-danger">*</span> : ''}
            <Input
              type="text"
              className={`form-control ${disabledField}`}
              value={fieldValue || ''}
              id={id}
              onChange={(e) => onHandleChangeValue(e)}
              disabled={isDisabled}
            />
          </FormGroup>
        );
      case 'email':
        return (
          <FormGroup>
            <Label>
              {name}
            </Label>
            {requiredValue ? <span className="text-danger">*</span> : ''}
            <Input
              type="email"
              value={fieldValue || ''}
              id={id}
              onChange={(e) => onHandleChangeValue(e)}
              disabled={isDisabled}
              className={`form-control ${disabledField}`}
            />
          </FormGroup>
        );
      case 'long_text':
        return (
          <FormGroup>
            <Label>
              {name}
            </Label>
            {requiredValue ? <span className="text-danger">*</span> : ''}
            <Input
              type="textarea"
              value={fieldValue || ''}
              id={id}
              onChange={(e) => onHandleChangeValue(e)}
              disabled={isDisabled}
              className={disabledField}
            />
          </FormGroup>
        );
      case 'numeric':
        return (
          <FormGroup>
            <Label>
              {name}
            </Label>
            {requiredValue ? <span className="text-danger">*</span> : ''}
            <Input
              type="number"
              min="1"
              value={fieldValue || ''}
              id={id}
              onChange={(e) => onHandleChangeValue(e)}
              disabled={isDisabled}
              className={disabledField}
            />
          </FormGroup>
        );
      case 'checkboxes':
        return (
          <FormGroup>
            <Label>{name}</Label>
            <ul className="custom-ul-layout">
              {getValues.map((obj, idx) => (
                <li key={`${type}_${idx}`}>
                  <Label className="checkbox-container checkbox-radio-container">
                    {requiredValue ? <span className="text-danger">*</span> : ''}
                    <Input
                      checked={onDefaultChecked(obj, fieldValue)}
                      type="checkbox"
                      value={obj}
                      id={id}
                      onChange={(e) => onCheckboxChecked(e, id)}
                      disabled={isDisabled}
                      className={disabledField}
                    />
                    {` ${obj}`}
                    <span className="checkmark checkmark-checkbox" />
                  </Label>
                </li>
              ))}
            </ul>
          </FormGroup>
        );
      case 'radio_button':
        return (
          <FormGroup>
            <Label>{name}</Label>
            <ul className="custom-ul-layout">
              {getValues.map((obj, idx) => (
                <li key={`${type}_${idx}`}>
                  <Label className="radio-container checkbox-radio-container">
                    {requiredValue ? <span className="text-danger">*</span> : ''}
                    <Input
                      checked={onDefaultChecked(obj, fieldValue)}
                      type="radio"
                      name={name}
                      value={obj}
                      id={id}
                      onChange={(e) => onHandleChangeValue(e)}
                      disabled={isDisabled}
                      className={disabledField}
                    />
                    {` ${obj}`}
                    <span className="checkmark checkmark-radio" />
                  </Label>
                </li>
              ))}
            </ul>
          </FormGroup>
        );
      case 'select_list':
        return (
          <FormGroup>
            <Label>
              {name}
            </Label>
            {requiredValue ? <span className="text-danger">*</span> : ''}
            {selectBoxOption(id, getValues, fieldValue, isDisabled)}
          </FormGroup>
        );
      case 'date': {
        let date;
        date = fieldValue && fieldValue?.[0] ? new Date(fieldValue[0]) : null;
        return (
          <FormGroup>
            <Label>
              {name}
            </Label>
            {requiredValue ? <span className="text-danger">*</span> : ''}
            <div>
              <DatePicker.default
                disabled={isDisabled}
                onChange={(e) => onDateSelect(e, id)}
                selected={date}
                showYearDropdown
                scrollableYearDropdown
                dropdownMode="scroll"
                className={`form-control ${disabledField}`}
              />
            </div>
          </FormGroup>
        );
      }
      default:
        return (
          <FormGroup>
            <Label>
              {name}
            </Label>
            {requiredValue ? <span className="text-danger">*</span> : ''}
            <Input />
          </FormGroup>
        );
    }
  };

  const getFieldData = (data) => data.map((items) => {
    const {
      attributes: {
        field_name, field_type, values, db_field_values, default: required_value,
      },
    } = items;

    const isIncludeSpecialField = specialFieldsArr.includes(field_name);
    if (memberId) {
      return (
        <Col lg={6} key={items.id}>
          {fieldType(items.id, field_name, field_type, values, required_value, db_field_values, isIncludeSpecialField)}
        </Col>
      );
    }
    return !isIncludeSpecialField && (
      <Col lg={6} key={items.id}>
        {fieldType(items.id, field_name, field_type, values, required_value, db_field_values, isIncludeSpecialField)}
      </Col>
    );
  });

  const renderFields = () => {
    if (!isEmpty(dbFields)) {
      const specialFields = dbFields.filter((ele) => ele.attributes.is_special);
      const normalFields = dbFields.filter((ele) => !ele.attributes.is_special);
      return (
        <div>
          <Row>{getFieldData(normalFields)}</Row>
          {!isEmpty(specialFields)
            && (
              <>
                <hr />
                <Row>{getFieldData(specialFields)}</Row>
              </>
            )}
        </div>
      );
    }
    return (
      <h4>Fields not available!</h4>
    );
  };

  const onHandleSubmit = async () => {
    const sendFieldsData = dbFieldsValue.map((field) => ({
      ...field, value: field.value.filter((ele) => ele.toString().trim() !== ''),
    }));
    const requiredFieldsValue = sendFieldsData.filter(
      (fieldValue) => dbFields.some((field) => fieldValue.key === field.id && field.attributes.default),
    ).every(
      (ele) => !isEmpty(ele.value),
    );
    if (requiredFieldsValue) {
      try {
        if (memberId) {
          const result = await updateMember({ instance_id: instanceId, member_id: memberId, data: dbFieldsValue });
          if (result && result?.data?.meta?.is_success) {
            displaySuccess(result?.data?.meta?.messages);
            setDbFieldsValue([]);
          }
        } else {
          const result = await createMember({ instance_id: instanceId, data: dbFieldsValue });
          if (result && result?.data?.meta?.is_success) {
            displaySuccess(result?.data?.meta?.messages);
            setDbFieldsValue([]);
            navigate(replacePathParams(dataCenter.editMember, [{ key: 'memberId', value: result.data.data.id }], props));
          }
        }
      } catch (error) {
        displayError(`${error?.name}: ${error?.message}`);
      }
      return true;
    }
    displayError('Please fill required fields!');
    return false;
  };

  const headingName = memberId ? 'Edit Member' : 'Add Member';

  addRecentItem({
    instance_id: instanceId,
    type: memberId ? 'edit-member' : 'add-member',
    item_id: memberId,
  });

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Data Centre',
      url: replacePathParams(mainRoutes.dataCenter, [], props),
    },
    { name: headingName, url: '' },
  ];

  return (
    <>
      <HeaderComponent setPath={{
        addNewPath: '',
        backBtnName: 'Back',
        addNewBtnName: '',
        staticBreadcrumbData,
        showBreadcrumb: false,
        headingName,
        backToPath: replacePathParams(mainRoutes.dataCenter, [], props),
      }}
      />
      <Card className="rounded">
        <div className="pl-2 d-flex flex-grow-1 min-width-zero">
          <div className="card-body">
            {renderFields()}
          </div>
        </div>
      </Card>
      <BottomActionToolbar
        component={(
          <SaveAndContinue
            onSave={() => onHandleSubmit()}
            onContinue={replacePathParams(dataCenter.allMembers, [
              { key: 'pageNo', value: '1' },
            ], props)}
          />
        )}
      />
    </>
  );
}

export default withRouter(DatabaseFields);
