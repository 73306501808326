import React, { useState, useEffect } from 'react';
import HeaderComponent from 'dashboard/src/components/common/header-component';
import { replacePathParams, settingRoutes, siteCenter } from 'dashboard/src/constants/routes';
import { get, isEmpty, map } from 'lodash-es';
import { useNavigate } from 'react-router';
import { Card, CardBody, Col, Input, Label, Row } from 'reactstrap';
import withRouter from 'dashboard/src/helpers/withRouter';
import AceEditor from 'react-ace';
import BottomActionToolbar from 'dashboard/src/components/common/toolbar';
import SaveAndContinue from 'dashboard/src/components/common/saveAndContinue';
import CustomSelect from 'dashboard/src/components/common/custom-select';
import { getPageHierarchy } from 'dashboard/src/components/common/PageHierarchy';
import { displayError, displaySuccess, getSelectedOption, selectBoxOptions } from '../../../../../../Utils';
import CustomTooltip from 'dashboard/src/components/common/CustomTooltip';
import {
  useCreateCustomCodeMutation,
  useGetCustomCodeQuery,
  useGetMasterPagesQuery,
  useGetOrphanPagesQuery,
  useGetPagesQuery,
  useGetTemplatesQuery,
  useUpdateCustomCodeMutation,
} from 'dashboard/src/api/apiSlice';
import { addRecentItem } from '../../../../../../UtilsTS';

const languages = [
  { label: 'HTML', value: 'html' },
  { label: 'JavaScript', value: 'javascript' },
  { label: 'CSS', value: 'css' },
  { label: 'SCSS', value: 'scss' },
];

const codePositionHTML = [
  { label: 'Inside <head>', value: 'head' },
  { label: 'At end of <body>', value: 'body' },
];

const codePositionJS = [
  { label: 'ready', value: 'ready' },
  { label: 'load', value: 'load' },
];

function CustomCode(props) {
  const { params: { id, instanceId, siteId } } = props;
  const [params, setParams] = useState({
    file_name: '',
    language: 'html',
    page_ids: [-1],
    template_ids: [-1],
  });
  const [editorError, setEditorError] = useState(null);

  const { data: pages } = useGetPagesQuery(
    { instanceId, siteId }, { skip: isEmpty(instanceId) && isEmpty(siteId) }
  );
  const { data: orphans } = useGetOrphanPagesQuery(
    { instanceId, siteId }, { skip: isEmpty(instanceId) && isEmpty(siteId) }
  );
  const { data: masters } = useGetMasterPagesQuery(
    { instanceId, siteId }, { skip: isEmpty(instanceId) && isEmpty(siteId) }
  );
  const { data: templates } = useGetTemplatesQuery(
    { instanceId, siteId }, { skip: isEmpty(instanceId) && isEmpty(siteId) }
  );

  const { data: customCode } = useGetCustomCodeQuery({ site_id: siteId, id }, { skip: isEmpty(siteId) || isEmpty(id) });
  const [create] = useCreateCustomCodeMutation();
  const [update] = useUpdateCustomCodeMutation();
  
  const allPages = [
    { label: 'All', value: -1 },
    ...getPageHierarchy([...(pages || []), ...(orphans || []), ...(masters || [])]),
  ];

  const templateOptions = isEmpty(templates) ? [] : [
    { label: 'All', value: -1 }, ...selectBoxOptions(templates, 'name', 'id'),
  ];

  const navigate = useNavigate();

  useEffect(() => {
    if (!isEmpty(customCode)) {
      setParams(customCode);
    }
  }, [customCode]);

  const onSubmit = async () => {
    if (params?.file_name === '') {
      displayError('Filename is required.');
    } else if (params?.language === '') {
      displayError('Language is required.');
    } else if (!isEmpty(editorError)) {
      displayError('Please fix the editor errors.');
    } else {
      if (id) {
        const result = await update({ site_id: siteId, ...params });
        if (result && result?.data?.meta?.is_success) {
          displaySuccess(result?.data?.meta?.messages);
        }
        return result;
      } else {
        const result = await create({ site_id: siteId, ...params });
        
        if (result && result?.data?.meta?.is_success) {
          displaySuccess(result?.data?.meta?.messages);
        }

        // We have just created a new record, navigate to the edit page so that subsequent saves don't try to create another new record.
        navigate(replacePathParams(siteCenter.customCodeUpdate, [{ key: 'id', value: result.data.data.id }], props));

        return true;
      }
    }
    return false;
  };

  const onChange = (e) => {
    const { name, value } = e;
    setParams({ ...params, [name]: value });
  };

  const onChangeCodeAppliesTo = (e, name) => {
    if (isEmpty(e)) {
      setParams({ ...params, [name]: e });
    }

    const setValue = Array.isArray(e) ? e.map((x) => x.value) : [];

    if (setValue.includes(-1)) {
      const getIndex = setValue.indexOf(-1);
      if (getIndex > -1) {
        setValue.splice(getIndex, 1);
        setParams({ ...params, [name]: [-1] });
      }
    } else {
      if (name === 'page_ids') {
        setParams({ ...params, page_ids: setValue, template_ids: [] });
      } else {
        setParams({ ...params, page_ids: [], template_ids: setValue });
      }
    }
  };

  const validateEditor = (error) => {
    // The HTML validation of the ACE editor is too strict, it doesn't like things like "&" characters in URLs, it
    // wants them to be escaped as &amp;, and no one does this...
    if (params.language === 'html') {
      return;
    }

    const filterError = error.filter((item) => item.type !== 'info' && item.type !== 'warning');
    if (isEmpty(filterError)) {
      setEditorError(null);
    } else {
      setEditorError(filterError);
    }
  };

  if (instanceId && siteId) {
    addRecentItem({
      instance_id: instanceId,
      site_id: siteId,
      type: id ? 'edit-custom-code' : 'add-custom-code',
      item_id: id,
    });
  }

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: replacePathParams(settingRoutes.dashboard, [], props) },
    {
      name: 'Site Centre',
      url: replacePathParams(siteCenter.pageList, [], props),
    },
    {
      name: 'Design Options',
      url: replacePathParams(siteCenter.designOption, [], props),
    },
    {
      name: 'Custom Codes',
      url: replacePathParams(siteCenter.customCodeListing, [], props),
    },
    { name: get(params, 'file_name', ''), url: '' },
  ];

  const codePositionOptions = params?.language === 'html' ? codePositionHTML : codePositionJS;

  const selectedValues = (name) => {
    if (!isEmpty(params?.[name]) && params?.[name].indexOf(-1) > -1) {
      return [{ label: 'All', value: -1 }];
    }
    const options = name === 'page_ids' ? allPages : templateOptions;
    return options.filter((t) => params?.[name].includes(t.value)) || [];
  };

  return (
    <>
      <HeaderComponent setPath={{
        headingName: id ? 'Editing Custom Code' : 'Add Custom Code',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        staticBreadcrumbData,
        showBreadcrumb: false,
      }}
      />
      <Card>
        <CardBody>
          <Row className="mb-4">
            <Col>
              <Label>Name</Label>
              <Input
                type="text"
                name="file_name"
                value={get(params, 'file_name', '')}
                onChange={(e) => onChange(e.target)}
              />
            </Col>
            <Col>
              <Label>Language</Label>
              <CustomSelect
                value={getSelectedOption(languages, get(params, 'language', ''))}
                onChange={(e) => onChange({ name: 'language', value: e.value })}
                options={languages}
              />
            </Col>
            {params && ['html', 'javascript'].includes(params?.language) && (
              <Col>
                <CustomTooltip name={`CustomCodePosition${params?.language}`}>
                  <Label className="has-tooltip">Code Position</Label>
                </CustomTooltip>
                <CustomSelect
                  value={getSelectedOption(codePositionOptions, get(params, 'code_position', ''))}
                  onChange={(e) => onChange({ name: 'code_position', value: e.value })}
                  options={codePositionOptions}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <CustomTooltip name="CustomCodeAppliesTo">
                <Label className="has-tooltip">Code Applies To</Label>
              </CustomTooltip>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <Label>Pages</Label>
              <CustomSelect
                isMulti
                options={allPages}
                closeMenuOnSelect={false}
                value={selectedValues('page_ids')}
                onChange={(e) => onChangeCodeAppliesTo(e, 'page_ids')}
              />
            </Col>
            <Col>
              <Label>Templates</Label>
              <CustomSelect
                isDisabled={!isEmpty(params?.page_ids) && !params?.page_ids.includes(-1)}
                isMulti
                options={templateOptions}
                closeMenuOnSelect={false}
                value={selectedValues('template_ids')}
                onChange={(e) => onChangeCodeAppliesTo(e, 'template_ids')}

              />
            </Col>
          </Row>
          <Row>
            {editorError && <div className="form-control is-invalid mb-3">{map(editorError, (error, i) => (
              <span className="text-danger d-block" key={i}>{`Line ${error.row + 1} Column  ${error.column}, ${error.text}`}</span>
            ))}</div>}
            <Col>
              <Label>Code</Label>
              <AceEditor.default
                className="ace-editor"
                mode={params?.language}
                theme="xcode"
                name="code-editor"
                onChange={(value) => onChange({ name: 'code', value })}
                onBlur={(e, code) => console.log(e, code)}
                height="500px"
                width="100%"
                value={params?.code}
                highlightActiveLine
                showGutter
                fontSize={16}
                setOptions={{
                  enableSnippets: true,
                  showLineNumbers: true,
                  tabSize: 2,
                }}
                editorProps={{ $blockScrolling: true }}
                onValidate={validateEditor}
              />
            </Col>
          </Row>

        </CardBody>
      </Card >
      <BottomActionToolbar
        component={(
          <SaveAndContinue
            onSave={onSubmit}
            onContinue={replacePathParams(siteCenter.customCodeListing, [], props)}
          />
        )}
      />
    </>
  );
}

export default withRouter(CustomCode);
