/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { createStore } from '../../../../rootStore';
import { useDispatch, useSelector } from 'react-redux';
import { selectBoxOptions, setCookie } from '../../../../Utils';
import CustomSelect from '../../components/common/custom-select';
import { get, map } from 'lodash-es';
import { FixedSizeList as List } from 'react-window';
import './InstanceSelection.scss';
import withRouter from '../../helpers/withRouter';
import { apiSlice, setInstance, useGetInstancesQuery } from '../../api/apiSlice';
import { QuickScore } from 'quick-score';

const height = 35;

export const getInstanceOptions = (data) => map(data, (opt) => (
  { label: opt.company_name, value: opt.id }));

export function MenuList(props) {
  const {
    options, children, maxHeight, getValue,
  } = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;
  return (
    <List
      height={maxHeight}
      itemSize={height}
      itemCount={children.length}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
}

function InstanceSelection() {
  const [hover, setHover] = useState(false);

  const store = createStore();
  const dispatch = useDispatch();
  const { data: instances } = useGetInstancesQuery();
  const [filteredInstances, setFilteredInstances] = useState([]);

  const { instance } = useSelector((s) => s.adminStore.instanceReducer);
  const instanceOptions = selectBoxOptions(instances, 'company_name', 'id');

  useEffect(() => {
    if (instances) {
      dispatch(setInstance({ data: instances, key: 'instancesList' }));
      setFilteredInstances(instanceOptions);
    }
  }, [instances]);

  if (!instance) {
    return <></>;
  }

  const selectedInstance = instances?.find((i) => i.id == instance.id);
  const hasMultipleInstances = instances.length > 1;

  const handleInputChange = (searchString) => {
    if (searchString) {
      const qs = new QuickScore(instanceOptions, ['company_name']);
      const results = qs.search(searchString);
      setFilteredInstances(map(results, (e) => ({ label: e?.item?.company_name, value: e?.item?.id })));
    } else {
      setFilteredInstances(instanceOptions);
    }
  }

  return (
    <div
      className="instance-selection-cont"
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <span
        style={{
          cursor: hasMultipleInstances ? 'pointer' : 'auto',
          borderBottom: hasMultipleInstances ? '1px dashed' : 'none',
        }}
      >
        {get(selectedInstance, 'company_name', '')}
      </span>
      {hasMultipleInstances && (
        <i style={{ color: 'var(--muted-color)' }} className="fa fa-caret-down ms-2" />
      )}

      {hasMultipleInstances && hover && (
        <div className="instance-selection-hover-cont">
          <div className="instance-selection-select-cont">
            <CustomSelect
              className="InstanceSelection"
              autoFocus
              isSearchable
              menuPlacement="auto"
              menuPosition="fixed"
              menuIsOpen
              value={{ label: get(selectedInstance, 'company_name', ''), value: get(selectedInstance, 'id', '') }}
              components={{ MenuList }}
              filterOption={null}
              options={filteredInstances}
              onInputChange={handleInputChange}
              onChange={async (e) => {
                const tempInstance = instances.find((i) => i.id == e.value);

                const resp = await store.dispatch(apiSlice.endpoints.getSites.initiate(tempInstance.id));
                const sites = get(resp, 'data.data', []);

                setCookie('INSTANCE_ID', tempInstance.id, 9999, '/');
                setCookie('SITE_ID', sites?.[0]?.id, 9999, '/');

                if (!window.location.href.match(tempInstance.id)) {
                  window.location.href = `/dashboards/instance/${tempInstance.id}/site/${sites?.[0]?.id}`;
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default withRouter(InstanceSelection);
