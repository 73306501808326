import React from 'react';
import { Card, CardBody } from 'reactstrap';
import withRouter from '../../helpers/withRouter';
import {
  useCreateEmailSeriesMutation,
  useGetEmailSeriesByIdQuery,
  useGetEmailsQuery,
  useGetSitesQuery,
  useUpdateEmailSeriesMutation,
} from '../../api/apiSlice';
import './index.scss';

function Test(props) {
  const resp = useGetEmailSeriesByIdQuery({ id: '65430a4186a4c3044a1bcd74', instance_id: '652f51ec236b7a3e02183c0a' });
console.log(resp);
const { data: emailSeries, meta: getSeriesMeta } = resp;
  const [updateSeries, { meta: updateSeriesMeta }] = m(useUpdateEmailSeriesMutation());
console.log(getSeriesMeta, updateSeriesMeta);

  return (
    <Card>
      <CardBody>
        <h1>{emailSeries?.name}</h1>
      </CardBody>
    </Card>
  );
}

export default withRouter(Test);
