import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Table,
  Button,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  DropdownToggle,
} from 'reactstrap';
import { isEmpty, get } from 'lodash-es';
import { DateTime } from 'luxon';
import DateTimeFormat from '../../components/common/DateTimeFormat';
import CustomButton from '../../components/common/button';
import confirm from '../../components/common/confirm';
import { replacePathParams, settingRoutes } from '../../constants/routes';
import withRouter from '../../helpers/withRouter';
import getCTAFormFields from './getCTAFormFields';
import CtaReport from './index';
import { useInstance, useUser } from '../../hooks';
import { useDeleteCtaLogMutation, useGetCtaLogQuery, useMarkSpamCtaLogMutation } from '../../api/apiSlice';
import CtaAPI from '../../api/cta_report';

const assignReactKeyToChildren = (d) => React.Children.toArray(d);

function CtaReportFormSubmission(props) {
  const [orderBy, setOrderBy] = useState('created_at');
  const [orderDirection, setOrderDirection] = useState('desc');
  const [dropdownOpen, setDropdown] = useState([]);
  const [currentCTA, setCurrentCTA] = useState(null);
  const [showModal, setModal] = useState(false);
  const [spamCol, setSpanCol] = useState([]);
  const { hash } = useLocation();
  const navigate = useNavigate();
  const instance = useInstance();
  const instanceId = instance?.id || null;

  const user = useUser();

  const { data: ctaLog = {} } = useGetCtaLogQuery(
    { instance_id: instanceId, cta_log_id: hash.replace(/[\\#]/g, '') },
    { skip: isEmpty(instanceId) || isEmpty(hash) }
  );

  const [deleteCtaLog] = useDeleteCtaLogMutation();
  const [markSpamCtaLog] = useMarkSpamCtaLogMutation();

  useEffect(() => {
    if (!isEmpty(ctaLog)) {
      setModal(true);
      setCurrentCTA(ctaLog);
    }
  }, [ctaLog]);

  const toggle = (index) => {
    const tempState = [...dropdownOpen];
    tempState[index] = !tempState[index];
    setDropdown(tempState);
  };

  const onHandleDateField = (cta) => {
    setCurrentCTA(cta);
    setModal(true);
  };

  const closeModal = (e) => {
    if (e) {
      navigate(replacePathParams(settingRoutes.dashboard, [], props));
    }
    setCurrentCTA(null);
    setModal(false);
  };

  const showModalBox = () => {
    const isLog = !isEmpty(ctaLog) && true;
    return (
      <Modal isOpen={showModal} toggle={() => closeModal(isLog)}>
        <ModalHeader toggle={() => closeModal(isLog)}>
          Form Submission
        </ModalHeader>
        <ModalBody>
          <Table>
            <tbody>
              {React.Children.toArray(
                currentCTA.attributes.data.map((el) => getCTAFormFields(el, true)),
              )}
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter className="pt-3 pe-3 pb-3 ps-3">
          <Button size="sm" color="danger" onClick={() => closeModal(isLog)}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  };

  const onAttachmentClick = async (fileId, fileName) => {
    const params = {
      instance_id: instanceId,
      file_id: fileId,
      filename: fileName,
    };
    try {
      const response = await CtaAPI.ctaLogAttachment(params);
      const { request: { responseURL } } = response;
      window.location.href = responseURL;
    } catch (err) {
      console.log(err);
    }
  };

  const getLogAttachments = (data, index) => {
    if (!isEmpty(data)) {
      const { attributes: { attachments } } = data;
      if (!isEmpty(attachments)) {
        return (
          <ButtonDropdown isOpen={dropdownOpen[index]} toggle={() => toggle(index)}>
            <DropdownToggle className="custom-simple-txt-btn">
              <i className="fa fa-paperclip fw-normal font-size-15px" aria-hidden="true" />
            </DropdownToggle>
            <DropdownMenu end>
              {
                attachments.map((item) => (
                  <DropdownItem
                    className="custom-cursor-pointer-draggable"
                    key={item}
                    onClick={() => onAttachmentClick(item.id, item.filename)}
                  >
                    {item.filename}
                  </DropdownItem>

                ))
              }
            </DropdownMenu>
          </ButtonDropdown>
        );
      }
    }
    return null;
  };

  const onClickSpam = async (id) => {
    const sendParams = {
      id,
      log: id,
      instance_id: instanceId,
    };
    await markSpamCtaLog(sendParams);
  };

  const onHandleMouseOver = (index) => {
    const tempState = [...spamCol];
    tempState[index] = !tempState[index];
    setSpanCol(tempState);
  };

  const onHandleDelete = async (id) => {
    const result = await confirm({
      title: <b>Confirm!</b>,
      message: 'Are you sure?',
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });

    if (result) {
      const params = {
        id,
        instance_id: instanceId,
      };
      await deleteCtaLog(params);
    }
  };

  const dataTableColumns = [
    {
      Header: (
        <Button
          onClick={() => {
            setOrderBy('created_at');
            setOrderDirection(orderDirection === 'desc' ? 'asc' : 'desc');
          }}
          className="custom-simple-txt-btn fw-bold"
        >
          <span>
            {'Date '}
            {orderBy === 'created_at' ? (
              <i
                className={` ${orderDirection === 'desc' ? 'fa fa-chevron-circle-up' : 'fa fa-chevron-circle-down'}`}
              />
            ) : ''}
          </span>
        </Button>
      ),
      id: 'Date',
      className: 'text-nowrap',
      disableSortBy: true,
      accessor: (d) => (
        <Button
          onClick={() => onHandleDateField(d)}
          disabled={d.attributes.type === 'CTALogOutboundClick'}
          className="custom-simple-txt-btn cta-log-more-info"
        >
          <DateTimeFormat datetime={get(d, 'attributes.created_at', '')} localeString={DateTime.DATETIME_SHORT} />
        </Button>
      ),
      isVisible: true,
    },
    {
      Header: 'Origin',
      id: 'Origin',
      className: 'd-none d-lg-table-cell',
      disableSortBy: true,
      accessor: (d) => get(d, 'attributes.page.page_name', ''),
    },
    {
      Header: 'Site',
      id: 'Site',
      className: 'd-none d-lg-table-cell',
      disableSortBy: true,
      accessor: (d) => get(d, 'attributes.site.hostname', ''),
    },
    {
      Header: 'Attachments',
      id: 'Attachments',
      className: 'd-none d-lg-table-cell',
      disableSortBy: true,
      accessor: (d, index) => getLogAttachments(d, index),
      isVisible: true,
    },
    {
      Header: 'Spam',
      id: 'Spam',
      className: 'd-none d-lg-table-cell',
      disableSortBy: true,
      isVisible: true,
      accessor: (d, index) => user?.hasFeature('view_spam_ctas') && (
        d.attributes.spam
        && (
          <>
            <Button
              id={`spam_${index}`}
              className="custom-simple-txt-btn"
              onMouseOver={() => onHandleMouseOver(index)}
              onMouseOut={() => onHandleMouseOver(index)}
              onClick={() => onClickSpam(d.id, index)}
            >
              <i
                className={
                  `${d.attributes.spam
                    ? 'far fa-exclamation-triangle'
                    : 'far fa-check-square'} font-size-15px`
                }
              />
            </Button>
            <Tooltip placement="top" isOpen={spamCol[index]} target={`spam_${index}`}>
              {d.attributes.spam && 'Mark as not spam'}
            </Tooltip>
          </>
        )
      ),
    },
    {
      Header: 'Actions',
      id: 'Actions',
      disableSortBy: true,
      accessor: (d) => (
        user?.hasFeature('delete_cta_logs')
        && (
          <CustomButton
            size="xs"
            title=" "
            color="danger"
            icon="fal fa-trash"
            className="custom-simple-icon"
            style={{ background: '#dc3545' }}
            onClick={() => onHandleDelete(d.id)}
          />
        )
      ),
    },
  ];

  return (
    <>
      <CtaReport
        instanceId={instanceId}
        dataTableColumns={dataTableColumns}
        orderBy={orderBy}
        orderDirection={orderDirection}
        ctaType="CTALogFormSubmission"
        typeName={['lead', 'leads']}
        subRow={(columns, row) => (
          <tr>
            <td colSpan={columns.length + 1}>
              {assignReactKeyToChildren(
                row.original.attributes.data.map((el) => (
                  <span
                    className="me-2 text-ellipsis"
                  >
                    {getCTAFormFields(el)}
                  </span>
                )),
              )}
            </td>
          </tr>
        )}
      />
      {showModal && showModalBox()}
    </>
  );
}

export default withRouter(CtaReportFormSubmission);
