import React, { useEffect, useState } from 'react';
import {
  Button, ButtonGroup, Label, UncontrolledTooltip,
  Card, CardBody, Input,
} from 'reactstrap';
import { isEmpty, cloneDeep, isEqual } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import CarbonCopyEmail from '../../../components/common/carbonCopyEmail';
import HeaderComponent from '../../../components/common/header-component';
import { getPageHierarchy } from '../../../components/common/PageHierarchy';
import TableComponent from '../../../components/common/Table';
import BottomActionToolbar from '../../../components/common/toolbar';
import SaveAndContinue from '../../../components/common/saveAndContinue';
import { siteCenter, replacePathParams, settingRoutes } from '../../../constants/routes';
import { getArrIndex, getSelectedOption, selectColorStyles } from '../../../../../Utils';
import withRouter from '../../../helpers/withRouter';
import {
  setInstance, useGetInstanceDataQuery, useGetSectionsByTypeQuery, useSectionUpdateMutation,
} from '../../../api/apiSlice';
import UserFeaturePage from '../../../components/common/user-feature-page';
import { addRecentItem } from '../../../../../UtilsTS';
import { useSite } from '../../../hooks';

const getForm = (id, forms) => {
  const form = forms.find((f) => f.id === id);
  if (!form) {
    return;
  }
  return form;
};

function EditAllForms(props) {
  const { params: { instanceId, siteId } } = props;
  const dispatch = useDispatch();
  const { sectionsData } = useSelector((state) => state.adminStore.instanceReducer);
  const site = useSite();
  const [version, setVersion] = useState('published');
  const [pageList, setPageList] = useState([]);
  const [allForms, setAllForms] = useState([]);
  const [originalForms, setOriginalForms] = useState([]);
  const { data } = useGetInstanceDataQuery(instanceId, { skip: isEmpty(instanceId) || !isEmpty(sectionsData) });
  const { data: sectionsByType, refetch } = useGetSectionsByTypeQuery({
    instanceId, siteId, version, type: 'SectionForm',
  });
  const [sectionUpdate, { isSuccess }] = useSectionUpdateMutation();

  useEffect(() => {
    if (data) {
      dispatch(setInstance({ data, key: 'sectionsData' }));
    }
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!isEmpty(sectionsData)) {
      const siteData = sectionsData.filter((item) => item.id == instanceId)[0].sites;

      const pageList = siteData.filter((item) => item.id == siteId)[0].pages;
      const pageHierarchy = getPageHierarchy(pageList, '');

      setPageList(pageHierarchy);
    }
  }, [sectionsData, instanceId, siteId]);

  useEffect(() => {
    if (!isEmpty(sectionsByType)) {
      setAllForms(sectionsByType);

      if (isEmpty(originalForms)) {
        setOriginalForms(cloneDeep(sectionsByType));
      }
    }
  }, [sectionsByType]);

  const setFormValue = (id, field, value) => {
    const tempForms = [...allForms];

    if (isEmpty(tempForms)) {
      return;
    }

    const getIndex = getArrIndex(tempForms, 'id', id);
    tempForms[getIndex] = { ...tempForms[getIndex], [field]: value };
    setAllForms(tempForms);
  };

  const addEmailAddress = (id, email) => {
    const form = getForm(id, allForms);

    if (!form) {
      return;
    }

    setFormValue(id, 'email_addresses', email);
  };

  const onSave = () => {
    const toSave = {};
    const promises = [];

    allForms.forEach((form) => {
      const originalForm = originalForms.find((f) => f.id === form.id);
      if (!originalForm) {
        return;
      }

      Object.keys(form).forEach((k) => {
        if (k.match(/^temp_/)) {
          return;
        }

        if (isEqual(originalForm[k], form[k])) {
          return;
        }

        toSave[form.id] = form;
      });
    });

    Object.values(toSave).forEach((form) => {
      promises.push(new Promise((resolve) => {
        sectionUpdate({ instance_id: instanceId, section_id: form.id, ...form }).then(resolve);
      }));
    });

    return Promise.all(promises);
  };

  if (instanceId) {
    addRecentItem({
      instance_id: instanceId,
      type: 'edit-all-forms',
    });
  }

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: replacePathParams(settingRoutes.dashboard, [], props) },
    { name: 'Site Centre', url: replacePathParams(siteCenter.pageList, [], props) },
    { name: 'Edit All Forms', url: '' },
  ];

  const dataTableColumns = [
    {
      Header: 'Form',
      id: 'form',
      accessor: (d) => `${d.page_name} >> ${d.sector} >> ${d.column_section ? `Section ${d.column_section.order} >> Column ${d.order}` : `Section ${d.order}`}`,
    },
    {
      Header: (
        <>
          {site?.default_form_email_subject && (
            <>
              <Label className="has-tooltip mb-0" id="emailSubjectHelp">Email Subject</Label>
              <UncontrolledTooltip
                placement="right"
                target="emailSubjectHelp"
                autohide={false}
                style={{ textAlign: 'left' }}
              >
                The default email subject is &quot;
                <b>{site?.default_form_email_subject}</b>
                &quot;.
                <br />
                <br />
                Enter a different subject in this field to override the default.
              </UncontrolledTooltip>
            </>
          )}
          {!site?.default_form_email_subject && (
            <>Email Subject</>
          )}
        </>
      ),
      id: 'subject',
      accessor: (d) => (
        <Input
          type="text"
          placeholder={site?.default_form_email_subject}
          value={d.email_subject_line}
          onChange={(e) => setFormValue(d.id, 'email_subject_line', e.target.value)}
        />
      ),
    },
    {
      Header: 'Adds member to database',
      id: 'member',
      className: 'text-center align-middle',
      accessor: (d) => (
        <Input
          type="checkbox"
          checked={d.add_to_database}
          onChange={(e) => setFormValue(d.id, 'add_to_database', e.target.checked)}
        />
      ),
    },
    {
      Header: 'Redirects to',
      id: 'redirectto',
      accessor: (d) => (
        <Select
          name="redirect_page_id"
          isSearchable
          options={pageList}
          styles={selectColorStyles}
          onChange={(e) => setFormValue(d.id, 'redirect_page_id', e.value)}
          value={getSelectedOption(pageList, d.redirect_page_id)}
        />
      ),
    },
    {
      Header: (
        <>
          {site?.default_form_email_addresses?.length > 0 && (
            <>
              <Label className="has-tooltip mb-0" id="emailAddressHelp">Email Address</Label>
              <UncontrolledTooltip
                placement="right"
                target="emailAddressHelp"
                autohide={false}
                style={{ textAlign: 'left' }}
              >
                {site?.default_form_email_addresses.length === 1 && (
                  <>
                    The default email address is
                    {' '}
                    <b>{site?.default_form_email_addresses[0]}</b>
                    .
                    <br />
                  </>
                )}
                {site?.default_form_email_addresses.length > 1 && (
                  <>
                    The default email addresses are:
                    <br />
                    <br />
                    <b>
                      {site?.default_form_email_addresses.map((a, i) => <div key={i}>{a}</div>)}
                    </b>
                  </>
                )}
                <br />
                Enter different addresses in this field to override the default.
              </UncontrolledTooltip>
            </>
          )}
          {!(site?.default_form_email_addresses?.length > 0) && (
            <>Email Address</>
          )}
        </>
      ),
      id: 'emails',
      accessor: (d) => (
        <>
          <CarbonCopyEmail
            placeholder="Add email..."
            id="default_form_email_addresses"
            name="default_form_email_addresses"
            selectedEmails={getForm(d.id, allForms)?.email_addresses || []}
            onChange={(e) => addEmailAddress(d.id, e)}
          />
        </>
      ),
    },
    {
      Header: (
        <>
          {site?.default_form_mobile_number && (
            <>
              <Label className="has-tooltip mb-0" id="mobileNumberHelp">Mobile Number</Label>
              <UncontrolledTooltip
                placement="right"
                target="mobileNumberHelp"
                autohide={false}
                style={{ textAlign: 'left' }}
              >
                The default mobile number is
                {' '}
                <b>{site?.default_form_mobile_number}</b>
                .
                <br />
                <br />
                Enter a different mobile number in this field to override the default.
              </UncontrolledTooltip>
            </>
          )}
          {!site?.default_form_mobile_number && (
            <>Mobile Number</>
          )}
        </>
      ),
      id: 'mobile',
      accessor: (d) => (
        <Input
          type="text"
          placeholder={site?.default_form_mobile_number}
          value={d.mobile_number}
          onChange={(e) => setFormValue(d.id, 'mobile_number', e.target.value)}
        />
      ),
    },
  ];

  return (
    <>
      <HeaderComponent
        setPath={{
          headingName: 'Edit All Forms',
          addNewBtnName: '',
          addNewPath: '',
          backBtnName: 'Back',
          backToPath: settingRoutes.dashboard,
          showBreadcrumb: false,
          staticBreadcrumbData,
        }}
      />
      <UserFeaturePage feature="edit_all_forms">
        <Card>
          <CardBody>
            <ButtonGroup>
              <Button
                color="primary"
                outline
                onClick={() => setVersion('published')}
                active={version === 'published'}
              >
                Published
              </Button>
              <Button
                color="primary"
                outline
                onClick={() => setVersion('latest')}
                active={version === 'latest'}
              >
                Latest
              </Button>
            </ButtonGroup>
            <hr />
            <TableComponent columns={dataTableColumns} data={allForms} />
          </CardBody>
        </Card>
        <BottomActionToolbar
          component={(
            <SaveAndContinue
              onSave={onSave}
              onContinue={replacePathParams(siteCenter.pageList, [], props)}
            />
          )}
        />
      </UserFeaturePage>
    </>
  );
}

export default withRouter(EditAllForms);
