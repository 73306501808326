import React, { useState } from 'react';
import { Card, Alert, Button, CardBody, Badge, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { displayError, displaySuccess } from '../../../../../Utils';
import confirm from 'dashboard/src/components/common/confirm';
import HeaderComponent from 'dashboard/src/components/common/header-component';
import { replacePathParams, settingRoutes, siteCenter } from 'dashboard/src/constants/routes';
import CustomButton from 'dashboard/src/components/common/button';
import BottomActionToolbar from 'dashboard/src/components/common/toolbar';
import { get, isEmpty } from 'lodash-es';
import withRouter from 'dashboard/src/helpers/withRouter';
import TableComponent from 'dashboard/src/components/common/Table';
import Switch from 'rc-switch';
import PageSelectBox from 'dashboard/src/components/fields/page/PageSelectBox';
import { DateTime } from 'luxon';
import DateTimeFormat from 'dashboard/src/components/common/DateTimeFormat';
// import './DomainListing.scss';
import {
  useGetDomainsQuery,
  useUpdateDomainMutation,
  useDeleteDomainMutation,
  useAddNewDomainMutation,
  useMakeDomainPrimaryMutation,
  useIssueDomainCertificateMutation,
} from 'dashboard/src/api/apiSlice';
import { ParseResultType, parseDomain } from 'parse-domain';
import { useInstance, useSite } from '../../../hooks';
import UserFeaturePage from '../../../components/common/user-feature-page';
import { addRecentItem } from '../../../../../UtilsTS';

const validateDomain = (value) => {
  const parseResult = parseDomain(value);
  if (isEmpty(value) || parseResult.type !== ParseResultType.Listed) {
    return { ...parseResult, invalid: true };
  }
  return { ...parseResult, invalid: false };
};

function DomainListing(props) {
  const site = useSite();
  const instance = useInstance();
  const siteId = get(site, 'id', null);
  const { data: domains = [] } = useGetDomainsQuery(siteId, { skip: isEmpty(siteId) });
  const [updateDomain] = useUpdateDomainMutation();
  const [deleteDomain] = useDeleteDomainMutation();
  const [addNewDomain] = useAddNewDomainMutation();
  const [makeDomainPrimary] = useMakeDomainPrimaryMutation();
  const [issueDomainCertificate] = useIssueDomainCertificateMutation();
  const [tempDomainNames, setTempDomainNames] = useState({});
  const [modal, setModal] = useState(false);
  const [domain, setDomain] = useState('');
  
  if (instance?.id) {
    addRecentItem({
      instance_id: instance.id,
      type: 'domains',
    });
  }

  const onHandleDelete = async (domain) => {
    const result = await confirm({
      title: (<strong>Confirm!</strong>),
      message: 'Are you sure?',
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });
    if (result) {
      const result = await deleteDomain({ site_id: siteId, id: domain.id });
      if (result && result?.data?.meta?.is_success) {
        displaySuccess(result?.data?.meta?.messages);
      }
    }
  };

  const handleEnableHTTPS = (domain) => {
    issueDomainCertificate(domain);
  };

  const certificateStatus = (domain) => {
    if (domain?.issuing) {
      return (
        <div style={{ marginTop: '6px' }}>
          Issuing certificate...
        </div>
      );
    }
    if (domain.certificate_valid) {
      return (
        <div style={{ marginTop: '6px' }}>
          Expires:
          {' '}
          <DateTimeFormat datetime={domain.certificate_expiry} localeString={DateTime.DATETIME_SHORT} />
        </div>
      );
    }
    return (
      <button
        type="button"
        className="custom-simple-icon btn-xs btn btn-info"
        onClick={() => handleEnableHTTPS(domain)}
        style={{ marginTop: '4px' }}
        disabled={!domain.hasOwnProperty('id')}
      >
        Enable HTTPS
      </button>
    );
  };

  const onUpdate = async (payload) => {
    const result = await updateDomain(payload);
    if (result && result?.data?.meta?.is_success) {
      displaySuccess(result?.data?.meta?.messages);
    }
  };

  const changePlatform = async (checked, domain) => {
    const result = await confirm({
      title: <b>Move Platform?</b>,
      message: `This will point the domain to the ${checked ? 'NEW' : 'OLD'} hosting platform. Are you sure?`,
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });
    if (!result) {
      return;
    }
    onUpdate({ ...domain, platform: checked ? 2 : 1 });
  };

  const onChangeDomainName = (e, domain) => {
    setTempDomainNames({ ...tempDomainNames, [domain.id]: e.target.value });
  };

  const onKeyUpDomainName = (e, domain) => {
    if (e.keyCode === 13) {
      onHandleSave(domain);
      e.target.blur();
    }
  };

  const onHandleSave = (domain) => {
    const domainName = tempDomainNames[domain.id] || domain.domain_name;

    const validationResult = validateDomain(domainName);
    if (validationResult.invalid) {
      displayError('Domain is invalid.');
    } else {
      onUpdate({ id: domain.id, site_id: domain.site_id, domain_name: domainName });
    }
  };

  const domainChanged = (domain) => tempDomainNames[domain.id] && tempDomainNames[domain.id] !== domain.domain_name;

  const onHandleAddDomain = async () => {
    const validationResult = validateDomain(domain);
    if (validationResult?.invalid) {
      displayError('Domain is invalid.');
    } else {
      const result = await addNewDomain({ site_id: siteId, domain_name: domain });
      if (result && result?.data?.meta?.is_success) {
        setDomain('');
        setModal(false);
        displaySuccess(result?.data?.meta?.messages)
      }
    }
  };

  const dataTableColumns = [
    {
      Header: 'Domain Name',
      id: 'name',
      disableSortBy: true,
      accessor: (d) => (
        <Input
          autoFocus
          value={tempDomainNames[d.id] || d.domain_name}
          onChange={(e) => onChangeDomainName(e, d)}
          onKeyUp={(e) => onKeyUpDomainName(e, d)}
        />
      ),
    },
    {
      Header: 'Serve Page',
      id: 'serve_page',
      disableSortBy: true,
      accessor: (d) => !domainChanged(d) && !d.is_primary && (
        <PageSelectBox
          params={d}
          keyName="serve_page_id"
          onChange={(e) => onUpdate({ ...d, serve_page_id: e ? e.value : null })}
        />
      ),
    },
    {
      Header: 'Redirect To Page',
      id: 'redirect_page',
      disableSortBy: true,
      accessor: (d) => !domainChanged(d) && !d.is_primary && (
        <PageSelectBox
          params={d}
          keyName="redirect_page_id"
          onChange={(e) => onUpdate({ ...d, redirect_page_id: e ? e.value : null })}
        />
      ),
    },
    {
      Header: 'Primary',
      id: 'primary',
      disableSortBy: true,
      accessor: (d) => {
        if (domainChanged(d)) {
          return <></>;
        }

        const parseResult = parseDomain(d.domain_name);

        if (!parseResult || parseResult.type !== ParseResultType.Listed) {
          return <></>;
        }

        // WG-hosted sites are not allowed to have a root domain as the primary. This is because of the fact that root
        // domains can't be a CNAME, and we use CNAME records to point domains at our main server. SEO sites are an
        // exception, because they are not hosted by us, so we can't set the rules. If an SEO site really uses a root
        // domain as their primary domain then we should set it as the primary in the dashboard -- this is important
        // for Search Console and Majestic integration, and probably other services too.
        const hasWebsite = site?.products?.some((p) => {
          return p.name === 'Dominator' ||
            p.name === 'Dominator Starter' ||
            p.name === 'Sales Page' ||
            p.name === 'Firestarter'
          ;
        });

        if (parseResult.subDomains.length === 0 && (isEmpty(site?.products) || hasWebsite)) {
          return <></>;
        }

        return (
          <Button
            color={d.is_primary ? 'success' : 'info'}
            className="custom-simple-icon btn-xs"
            onClick={() => makeDomainPrimary(d)}
            disabled={d.is_primary || !d.hasOwnProperty('id')}
          >
            {d.is_primary ? 'Primary' : 'Make Primary'}
          </Button>
        );
      },
    },
    {
      Header: 'Hosted on new platform',
      id: 'new_platform',
      disableSortBy: true,
      accessor: (d) => !domainChanged(d) && (
        <Switch
          id="Tooltip-Switch"
          className="custom-switch custom-switch-primary custom-switch-small"
          checked={d.platform === 2}
          onChange={(e) => changePlatform(e, d)}
        />
      ),
    },
    {
      Header: 'HTTPS Status',
      id: 'status',
      disableSortBy: true,
      accessor: (d) => !domainChanged(d) && (
        <>
          Status:
          {' '}
          <Badge color={d.certificate_valid ? 'success' : 'danger'} onClick={(e) => e.preventDefault()}>
            <i className={`fal fa-${d.certificate_valid ? 'check' : 'times'}-circle`} />
          </Badge>
          <br />
          {certificateStatus(d)}
        </>
      ),
    },
    {
      Header: 'Action',
      id: 'action',
      disableSortBy: true,
      accessor: (d) => {
        const validationResult = validateDomain(tempDomainNames[d.id] || d.domain_name);
        return (
          <>
            {domainChanged(d) && (
              <>
                {validationResult?.invalid && (
                  <Badge color="danger">
                    <i className="fa-solid fa-times" />
                    {' '}
                    Domain is invalid
                  </Badge>
                )}
                {!validationResult?.invalid && (
                  <Button
                    size="xs"
                    color="success"
                    className="custom-simple-icon"
                    onClick={() => onHandleSave(d)}
                  >
                    <i className="fa-solid fa-check" />
                  </Button>
                )}
              </>
            )}

            {!domainChanged(d) && (
              <Button
                size="xs"
                color="danger"
                className="custom-simple-icon"
                onClick={() => onHandleDelete(d)}
                disabled={d.is_primary}
              >
                <i className="fal fa-trash" />
              </Button>
            )}
          </>
        );
      }
    },
  ];

  const onHandleToggle = () => {
    setDomain('');
    setModal(!modal);
  };

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: replacePathParams(settingRoutes.dashboard, [], props) },
    { name: 'Site Centre', url: replacePathParams(siteCenter.pageList, [], props) },
    { name: 'Domains', url: '' },
  ];

  if (domains) {
    const onOldPlatform = get(instance, 'platform_status', false) === 'old';

    return (
      <>
        <HeaderComponent setPath={{
          headingName: 'Domains',
          addNewBtnName: '',
          addNewPath: '',
          backBtnName: 'Back',
          backToPath: settingRoutes.dashboard,
          showBreadcrumb: false,
          staticBreadcrumbData,
        }}
        />
        <UserFeaturePage feature="domain_management">
          <Card className="domain-listing">
            <CardBody>
              <TableComponent data={domains} columns={dataTableColumns} />
            </CardBody>
          </Card>
          <BottomActionToolbar
            component={(
              onOldPlatform ? (
                <Alert color="danger" className="custom-alert-span">
                  This instance is hosted on the legacy Web Genius platform,
                  so you cannot make any changes to domains except for enabling HTTPS.
                </Alert>
              ) : (
                <CustomButton
                  color="info"
                  className="fw-bold"
                  title="ADD DOMAIN"
                  icon="fa fa-plus"
                  size="sm"
                  onClick={onHandleToggle}
                />
              )
            )}
          />
          {modal && (
            <Modal isOpen={modal} toggle={onHandleToggle} autoFocus={false}>
              <ModalHeader toggle={onHandleToggle}>New Domain</ModalHeader>
              <ModalBody>
                <Input
                  type="text"
                  value={domain}
                  placeholder="Domain Name"
                  onChange={(e) => setDomain(e.target.value)}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      onHandleAddDomain();
                    }
                  }}
                  autoFocus={true}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" onClick={onHandleToggle}>
                  <b>CANCEL</b>
                </Button>
                <Button color="success" onClick={onHandleAddDomain}>
                  <b>CREATE</b>
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </UserFeaturePage>
      </>
    );
  }

  return <h1>Loading...</h1>;
}

export default withRouter(DomainListing);
