import React, { useEffect, useState } from 'react';
import {
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupText,
  Input,
  Row,
  Card,
  CardTitle,
  CardBody,
  Col,
} from 'reactstrap';
import HeaderComponent from '../../../components/common/header-component';
import BottomActionToolbar from '../../../components/common/toolbar';
import SaveAndContinue from '../../../components/common/saveAndContinue';
import CustomSelect from '../../../components/common/custom-select';
import { getPageHierarchy } from '../../../components/common/PageHierarchy';
import { settingRoutes, mainRoutes, replacePathParams } from '../../../constants/routes';
import withRouter from '../../../helpers/withRouter';
import { useParams } from 'react-router';
import { useGetPagesQuery, useUpdateSiteMutation } from '../../../api/apiSlice';
import { isEmpty } from 'lodash-es';
import { useSite } from '../../../hooks';
import { displaySuccess } from '../../../../../Utils';

function SiteProfile(props) {
  const param = useParams();
  const { siteId, instanceId } = param;
  const [params, setParams] = useState(null);
  const [pageHierarchy, setPageHierarchy] = useState(null);
  const site = useSite();

  const { data: pageList = [] } = useGetPagesQuery({ instanceId, siteId }, { skip: isEmpty(instanceId) && isEmpty(siteId) });
  const [updateSite] = useUpdateSiteMutation();

  function TextAndCounter(tcProps) {
    const {
      params: { profile_info },
      fieldName,
      label,
      min,
      max,
      onChange,
    } = tcProps;
    const validObj = {};
    let type = 'textarea';

    if (max && max < 100) {
      type = 'text';
    }

    if (min && (!profile_info[fieldName] || profile_info[fieldName].length < min)) {
      validObj.invalid = true;
    } else if (max && profile_info[fieldName] && profile_info[fieldName].length > max) {
      validObj.invalid = true;
    } else {
      validObj.valid = true;
    }

    return (
      <FormGroup>
        <Label for={fieldName}>{label}</Label>
        <InputGroup>
          <Input
            type={type}
            name={fieldName}
            id={fieldName}
            value={profile_info[fieldName]}
            onChange={onChange}
            {...validObj}
          />
          <InputGroupText>{(profile_info[fieldName] || '').length}</InputGroupText>
        </InputGroup>
      </FormGroup>
    );
  }

  function PageList(plProps) {
    const {
      params: { profile_info },
      fieldName,
      label,
      onChange,
    } = plProps;

    return (
      <FormGroup>
        <Label for={fieldName}>{label}</Label>
        <CustomSelect
          options={pageHierarchy}
          name={fieldName}
          id={fieldName}
          value={pageHierarchy.filter((el) => el.value === profile_info[fieldName])}
          onChange={onChange}
        />
      </FormGroup>
    );
  }

  useEffect(() => {
    if (site && site.id) {
      setParams(site);
    }
  }, [site]);

  useEffect(() => {
    if (pageList) {
      setPageHierarchy(getPageHierarchy(pageList));
    }
  }, [pageList]);

  const onChange = (e, select) => {
    let name;
    let value;
    // We are getting events from textareas and from selects
    if (typeof select !== 'undefined') {
      name = select.name;
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    const newParams = { ...params };
    newParams.profile_info[name] = value;
    setParams(newParams);
  };

  const onHandleSubmit = async () => {
    const sendParams = {
      id: siteId,
      instance_id: instanceId,
      ...params,
    };
    const res = await updateSite(sendParams);
    if (res && res?.data?.meta?.is_success) {
      displaySuccess(res?.data?.meta?.messages);
    }
  };

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Settings',
      url: replacePathParams(mainRoutes.setting, [], props),
    },
    { name: 'Sites', url: '' },
  ];

  if (!params) {
    return <></>;
  }

  return (
    <>
      <HeaderComponent
        setPath={{
          headingName: 'Site Profile',
          addNewBtnName: '',
          addNewPath: '',
          backBtnName: 'Back',
          backToPath: replacePathParams(mainRoutes.setting, [], props),
          showBreadcrumb: false,
          staticBreadcrumbData,
        }}
      />
      <Card className="mb-4">
        <CardBody>
          <Row className="text-center">
            <Col lg={12}>
              <CardTitle className="fw-bold mb-3">Actions</CardTitle>
            </Col>
            <Col lg={12}>
              <SaveAndContinue
                onSave={() => onHandleSubmit()}
                onContinue={replacePathParams(mainRoutes.setting, [], props)}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Form>
        <Card className="mb-4">
          <CardBody>
            <CardTitle>SECTION 1 - Orientation Statement, 80-150 characters</CardTitle>
            {TextAndCounter({
              params,
              fieldName: 'Section_1_1',
              label: 'Version 1',
              min: 80,
              max: 150,
              onChange,
            })}
            {TextAndCounter({
              params,
              fieldName: 'Section_1_2',
              label: 'Version 2',
              min: 80,
              max: 150,
              onChange,
            })}
            {TextAndCounter({
              params,
              fieldName: 'Section_1_3',
              label: 'Version 3',
              min: 80,
              max: 150,
              onChange,
            })}
          </CardBody>
        </Card>
        <Card className="mb-4">
          <CardBody>
            <CardTitle>SECTION 2 - Sentences from MCP x 3</CardTitle>
            <p><strong>Sentence 1, 80-150 characters</strong></p>
            {TextAndCounter({
              params,
              fieldName: 'Section_2_1_1',
              label: 'Version 1',
              min: 80,
              max: 150,
              onChange,
            })}
            {TextAndCounter({
              params,
              fieldName: 'Section_2_1_2',
              label: 'Version 2',
              min: 80,
              max: 150,
              onChange,
            })}
            {TextAndCounter({
              params,
              fieldName: 'Section_2_1_3',
              label: 'Version 3',
              min: 80,
              max: 150,
              onChange,
            })}
            <p><strong>Sentence 2, 80-150 characters</strong></p>
            {TextAndCounter({
              params,
              fieldName: 'Section_2_2_1',
              label: 'Version 1',
              min: 80,
              max: 150,
              onChange,
            })}
            {TextAndCounter({
              params,
              fieldName: 'Section_2_2_2',
              label: 'Version 2',
              min: 80,
              max: 150,
              onChange,
            })}
            {TextAndCounter({
              params,
              fieldName: 'Section_2_2_3',
              label: 'Version 3',
              min: 80,
              max: 150,
              onChange,
            })}
            <p><strong>Sentence 3, 80-150 characters</strong></p>
            {TextAndCounter({
              params,
              fieldName: 'Section_2_3_1',
              label: 'Version 1',
              min: 80,
              max: 150,
              onChange,
            })}
            {TextAndCounter({
              params,
              fieldName: 'Section_2_3_2',
              label: 'Version 2',
              min: 80,
              max: 150,
              onChange,
            })}
            {TextAndCounter({
              params,
              fieldName: 'Section_2_3_3',
              label: 'Version 3',
              min: 80,
              max: 150,
              onChange,
            })}
          </CardBody>
        </Card>
        <Card className="mb-4">
          <CardBody>
            <CardTitle>SECTION 3</CardTitle>
            <p><strong>Subheads from SCPs, 20-35 characters</strong></p>
            {TextAndCounter({
              params,
              fieldName: 'Section_3_1_1',
              label: 'Version 1',
              min: 20,
              max: 35,
              onChange,
            })}
            {TextAndCounter({
              params,
              fieldName: 'Section_3_1_2',
              label: 'Version 1',
              min: 20,
              max: 35,
              onChange,
            })}
            {TextAndCounter({
              params,
              fieldName: 'Section_3_1_3',
              label: 'Version 1',
              min: 20,
              max: 35,
              onChange,
            })}
            <p><strong>Sentences from SCPs, 150-250 characters</strong></p>
            {TextAndCounter({
              params,
              fieldName: 'Section_3_2_1',
              label: 'Version 1',
              min: 150,
              max: 250,
              onChange,
            })}
            {TextAndCounter({
              params,
              fieldName: 'Section_3_2_2',
              label: 'Version 2',
              min: 150,
              max: 250,
              onChange,
            })}
            {TextAndCounter({
              params,
              fieldName: 'Section_3_2_3',
              label: 'Version 3',
              min: 150,
              max: 250,
              onChange,
            })}
            {PageList({
              params,
              fieldName: 'Link_3',
              label: 'Link',
              onChange,
            })}
          </CardBody>
        </Card>
        <Card className="mb-4">
          <CardBody>
            <CardTitle>SECTION 4</CardTitle>
            <p><strong>Subheads from SCPs, 20-35 characters</strong></p>
            {TextAndCounter({
              params,
              fieldName: 'Section_4_1_1',
              label: 'Version 1',
              min: 20,
              max: 35,
              onChange,
            })}
            {TextAndCounter({
              params,
              fieldName: 'Section_4_1_2',
              label: 'Version 1',
              min: 20,
              max: 35,
              onChange,
            })}
            {TextAndCounter({
              params,
              fieldName: 'Section_4_1_3',
              label: 'Version 1',
              min: 20,
              max: 35,
              onChange,
            })}
            <p><strong>Sentences from SCPs, 150-250 characters</strong></p>
            {TextAndCounter({
              params,
              fieldName: 'Section_4_2_1',
              label: 'Version 1',
              min: 150,
              max: 250,
              onChange,
            })}
            {TextAndCounter({
              params,
              fieldName: 'Section_4_2_2',
              label: 'Version 2',
              min: 150,
              max: 250,
              onChange,
            })}
            {TextAndCounter({
              params,
              fieldName: 'Section_4_2_3',
              label: 'Version 3',
              min: 150,
              max: 250,
              onChange,
            })}
            {PageList({
              params,
              fieldName: 'Link_4',
              label: 'Link',
              onChange,
            })}
          </CardBody>
        </Card>
        <Card className="mb-4">
          <CardBody>
            <CardTitle>SECTION 5</CardTitle>
            <p><strong>Subheads from SCPs, 20-35 characters</strong></p>
            {TextAndCounter({
              params,
              fieldName: 'Section_5_1_1',
              label: 'Version 1',
              min: 20,
              max: 35,
              onChange,
            })}
            {TextAndCounter({
              params,
              fieldName: 'Section_5_1_2',
              label: 'Version 1',
              min: 20,
              max: 35,
              onChange,
            })}
            {TextAndCounter({
              params,
              fieldName: 'Section_5_1_3',
              label: 'Version 1',
              min: 20,
              max: 35,
              onChange,
            })}
            <p><strong>Sentences from SCPs, 150-250 characters</strong></p>
            {TextAndCounter({
              params,
              fieldName: 'Section_5_2_1',
              label: 'Version 1',
              min: 150,
              max: 250,
              onChange,
            })}
            {TextAndCounter({
              params,
              fieldName: 'Section_5_2_2',
              label: 'Version 2',
              min: 150,
              max: 250,
              onChange,
            })}
            {TextAndCounter({
              params,
              fieldName: 'Section_5_2_3',
              label: 'Version 3',
              min: 150,
              max: 250,
              onChange,
            })}
            {PageList({
              params,
              fieldName: 'Link_5',
              label: 'Link',
              onChange,
            })}
          </CardBody>
        </Card>
      </Form>
      <BottomActionToolbar
        component={(
          <SaveAndContinue
            onSave={() => onHandleSubmit()}
            onContinue={replacePathParams(mainRoutes.setting, [], props)}
          />
        )}
      />
    </>
  );
}

export default withRouter(SiteProfile);
