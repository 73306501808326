import React, { useEffect, useState } from 'react';
import { Input, Row, Col, Card, CardBody, Label, Button } from 'reactstrap';
import { displayError, selectColorStyles, getSelectedOption, colourPalettePreset, displaySuccess } from '../../../../../../Utils';
import { isEmpty, get } from 'lodash-es';
import {
  FallbackFonts,
  filterVariants,
  Fonts,
  FontSize,
  FontStyles,
  FontWeight,
  HTMLTags,
  TemplateTypes,
  TextAlignStyle,
  TextDecorationStyle,
} from '../../../../components/common/textStyles';
import CustomSelect from '../../../../components/common/custom-select';
import { useAddNewTextStyleMutation, useGetSitesQuery, useGetTextStyleByIdQuery, useUpdateTextStyleMutation } from '../../../../api/apiSlice';
import BottomActionToolbar from '../../../../components/common/toolbar';
import { replacePathParams, settingRoutes, siteCenter } from '../../../../constants/routes';
import HeaderComponent from '../../../../components/common/header-component';
import withRouter from '../../../../helpers/withRouter';
import ColorPicker from '../../../../components/common/color-picker';
import { addRecentItem } from '../../../../../../UtilsTS';
import { useSite } from '../../../../hooks';

const TextStyle = (props) => {
  const { params: { siteId, id, instanceId }, navigate } = props;
  const [params, setParams] = useState({});
  const site = useSite();
  const [addNewTextStyle] = useAddNewTextStyleMutation();
  const [updateTextStyle] = useUpdateTextStyleMutation();
  const { data } = useGetTextStyleByIdQuery({ id, site_id: siteId }, { skip: isEmpty(id) ? true : false });
  const { data: sites } = useGetSitesQuery(instanceId, { skip: isEmpty(instanceId) });

  useEffect(() => {
    if (!isEmpty(data)) {
      setParams(data);
    }
  }, [data]);

  const onHandleChange = (e, keyName) => {
    const { value } = e;
    setParams({ ...params, [keyName]: value });
  };

  const templateType = get(params, 'type', '');
  const color = get(params, 'color', '');
  const linkColor = get(params, 'link_color', '');
  const breakPoint = get(params, 'break_point', {});

  const onHandleSubmit = async () => {
    const sendParams = {
      ...params,
      color: colourPalettePreset(color, get(site, 'colors', [])),
      link_color: colourPalettePreset(linkColor, get(site, 'colors', [])),
      break_point: templateType === 'email' ? [] : breakPoint,
    };

    if (!sendParams.name) {
      displayError('Text style name should not be blank!');
    } else if (!sendParams.site_id) {
      displayError('Site should not be blank!');
    } else if (templateType === '') {
      displayError('Template type should not be blank!');
    } else {
      if (isEmpty(id)) {
        const res = await addNewTextStyle(sendParams);
        if (res && res?.data?.meta?.is_success) {
          displaySuccess(res?.data?.meta?.messages);
          navigate(replacePathParams(siteCenter.editTextStyle, [{ key: 'id', value: res?.data?.data?.id }], props));
        }
      } else {
        const res = await updateTextStyle(sendParams);
        if (res && res?.data?.meta?.is_success) {
          displaySuccess(res?.data?.meta?.messages);
        }
      }
    }
  };

  if (instanceId && siteId) {
    addRecentItem({
      instance_id: instanceId,
      site_id: siteId,
      type: id ? 'edit-text-style' : 'add-text-style',
      item_id: id,
    });
  }

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: replacePathParams(settingRoutes.dashboard, [], props) },
    {
      name: 'Site Centre',
      url: replacePathParams(siteCenter.pageList, [], props),
    },
    {
      name: 'Design Options',
      url: replacePathParams(siteCenter.designOption, [], props),
    },
    {
      name: 'Text Styles',
      url: replacePathParams(siteCenter.textStyleList, [], props),
    },
    { name: get(params, 'name', ''), url: '' },
  ];

  return (
    <>
      <HeaderComponent setPath={{
        headingName: id ? 'Editing Text Style' : 'Add New Text Style',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        staticBreadcrumbData,
        showBreadcrumb: false,
      }}
      />
      <Card className="rounded">
        <CardBody>
          <Row className="mb-4">
            <Col md={4} className="mb-4">
              <Label className="fw-bold">Style name:</Label>
              <Input
                autoFocus
                placeholder="Enter name here"
                name="name"
                value={get(params, 'name', '')}
                onChange={(e) => setParams({ ...params, name: e.target.value })}
              />
            </Col>
            <Col md={4} className="mb-4">
              <Label className="fw-bold">Site:</Label>
              <CustomSelect
                value={getSelectedOption(sites, get(params, 'site_id', ''))}
                placeholder="Sites"
                options={sites}
                onChange={(e) => onHandleChange(e, 'site_id')}
                styles={selectColorStyles}
              />
            </Col>
            <Col>
              <Label className="fw-bold">Template type:</Label>
              <TemplateTypes
                value={templateType}
                onChange={(e) => {
                  setParams({
                    ...params,
                    type: e.value,
                    fallback_font: e.value === 'web' ? '' : params.fallback_font,
                  });
                }}
              />
            </Col>
            <Col md={4} className="mb-4">
              <Label className="fw-bold">Text align:</Label>
              <TextAlignStyle
                value={get(params, 'text_align', '')}
                onChange={(e) => onHandleChange(e, 'text_align')}
              />
            </Col>
            <Col>
              <Label className="fw-bold">HTML tag:</Label>
              <HTMLTags
                value={get(params, 'tag', '')}
                onChange={(e) => onHandleChange(e, 'tag')}
              />
            </Col>
            <Col md={4} className="mb-4">
              <Label className="fw-bold">Fonts:</Label>
              <Fonts
                font={get(params, 'fonts', '')}
                keyName="label"
                placeholder="Select Fonts"
                templateType={templateType}
                onChange={(e) => {
                  const getWeight = filterVariants(e.variants);
                  setParams({
                    ...params,
                    fonts: e.label,
                    variants: getWeight,
                    font_weight: !isEmpty(getWeight) ? getWeight[0].value : '',
                  });
                }}
              />
            </Col>
            <Col md={4} className="mb-4">
              <Label className="fw-bold">Font weight:</Label>
              <FontWeight
                fontName={get(params, 'fonts', '')}
                value={get(params, 'font_weight', '')}
                onChange={(e) => onHandleChange(e, 'font_weight')}
              />
            </Col>
            <Col md={4} className="mb-4">
              <Label className="fw-bold">Text decoration:</Label>
              <TextDecorationStyle
                value={get(params, 'text_decoration', '')}
                onChange={(e) => onHandleChange(e, 'text_decoration')}
              />
            </Col>
            <Col md={4} className="mb-4">
              <Label className="fw-bold">Font style:</Label>
              <FontStyles
                value={get(params, 'font_style', '')}
                onChange={(e) => onHandleChange(e, 'font_style')}
              />
            </Col>
            <Col md={4} className="mb-4">
              <Label className="fw-bold">Font size:</Label>
              <FontSize
                id={get(params, 'id', '')}
                siteId={get(params, 'site_id', '')}
                settingName="font-size"
                objectType="TextStyle"
                value={get(params, 'font_size', 10)}
                breakPoint={breakPoint}
                templateType={templateType}
                onChange={(e) => {
                  if (templateType === 'web') {
                    setParams({ ...params, break_point: e });
                  } else {
                    setParams({ ...params, font_size: e.target.value });
                  }
                }}
              />
            </Col>
            <Col md={4} className="mb-4">
              <Label className="fw-bold">Line height:</Label>
              <Input
                step={0.01}
                type="number"
                name="line_height"
                value={get(params, 'line_height', 0)}
                placeholder="Enter line height"
                styles={selectColorStyles}
                onChange={(e) => setParams({ ...params, line_height: e.target.value })}
              />
            </Col>
            {templateType === 'email' && (
              <Col md={4} className="mb-4">
                <Label className="fw-bold">Fallback font:</Label>
                <FallbackFonts
                  value={get(params, 'fallback_font', '')}
                  onChange={(e) => onHandleChange(e, 'fallback_font')}
                />
              </Col>
            )}
            <Col md={4} className="mb-4">
              <Label className="fw-bold">Colour:</Label>
              <ColorPicker
                color={color}
                onChange={({ target: { value } }) => setParams({ ...params, color: value })}
                onDeleteValue={() => setParams({ ...params, color: '' })}
              />
            </Col>
            <Col md={4} className="mb-4">
              <Label className="fw-bold">Link colour:</Label>
              <ColorPicker
                color={linkColor}
                onChange={({ target: { value } }) => setParams({ ...params, link_color: value })}
                onDeleteValue={() => setParams({ ...params, link_color: '' })}
              />
            </Col>
          </Row>
        </CardBody>

      </Card>
      <BottomActionToolbar
        component={(
          <Button color="success" onClick={() => onHandleSubmit()}>
            <b>SAVE</b>
          </Button>
        )}
      />
    </>
  );
};

export default withRouter(TextStyle);
