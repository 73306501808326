import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import HeaderComponent from '../../components/common/header-component';
import { dataCenter, replacePathParams, mainRoutes, settingRoutes } from '../../constants/routes';
import Select from 'react-select';
import { displayError, setPageTitle, selectColorStyles, selectBoxOptions, displaySuccess } from '../../../../Utils';
import { useParams } from 'react-router-dom';
import withRouter from '../../helpers/withRouter';
import { useDeleteSavedSearchesMutation, useGetSavedSearchesQuery } from '../../api/apiSlice';
import { isEmpty } from 'lodash-es';
import confirm from '../../components/common/confirm';

function SavedSearches(props) {
  const dispatch = useDispatch();
  const [selectedSavedSearch, setSelectedSaved] = useState(null);
  const { instanceId } = useParams();
  const { navigate } = props;

  const { data: savedSearchesData = [] } = useGetSavedSearchesQuery(instanceId, { skip: isEmpty(instanceId) });
  const [deleteSavedSearch] = useDeleteSavedSearchesMutation();

  useEffect(() => {
    setPageTitle('Saved Searches');
  }, [dispatch]);

  const onHandleChange = (e) => {
    const { value, label } = e;
    setSelectedSaved({ label, value });
  };

  const onHandleSelect = () => {
    if (selectedSavedSearch) {
      navigate(
        replacePathParams(dataCenter.showMemberSearch, [
          { key: 'searchId', value: selectedSavedSearch.value },
          { key: 'pageNo', value: 1 },
        ], props),
      );
    } else {
      displayError('Please select saved search!');
    }
  };

  const onHandleEdit = () => {
    if (selectedSavedSearch) {
      navigate(replacePathParams(dataCenter.memberSearchById, [
        { key: 'searchId', value: selectedSavedSearch.value },
      ], props));
    } else {
      displayError('Please select saved search!');
    }
  };

  const onHandleDelete = async () => {
    const result = await confirm({
      title: (<strong>Confirm!</strong>),
      message: 'Are you sure?',
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });
    if (result) {
      const res = await deleteSavedSearch(selectedSavedSearch.value);
      if (res && res?.data?.meta?.is_success) {
        setSelectedSaved(null);
        displaySuccess(res?.data?.meta?.messages);
      }
    }
  };

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Data Centre',
      url: replacePathParams(mainRoutes.dataCenter, [], props),
    },
    { name: 'Saved Searches', url: '' },
  ];

  return (
    <>
      <HeaderComponent
        setPath={{
          addNewPath: '',
          addNewBtnName: '',
          backBtnName: 'Back',
          staticBreadcrumbData,
          showBreadcrumb: false,
          headingName: 'Saved Searches',
          backToPath: mainRoutes.dataCenter,
        }}
      />
      <Row>
        <Col className="col-sm-12">
          <Card>
            <CardBody>
              <Row>
                <Col lg={9} className="mt-2">
                  <Select
                    placeholder="Please select option"
                    value={selectedSavedSearch || null}
                    options={selectBoxOptions(savedSearchesData, 'name', 'id')}
                    onChange={onHandleChange}
                    styles={selectColorStyles}
                  />
                </Col>
                <Col lg={3} className="mt-2">
                  <Button
                    color="success"
                    onClick={onHandleSelect}
                  >
                    <b>Select</b>
                  </Button>
                  {' '}
                  <Button color="info" onClick={onHandleEdit}>
                    <b>Edit</b>
                  </Button>
                  {' '}
                  <Button color="danger" onClick={onHandleDelete} disabled={isEmpty(selectedSavedSearch)}>
                    <b>Delete</b>
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default withRouter(SavedSearches);
