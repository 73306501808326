import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import AppLocale from './lang';
import { setTheme } from '../../Utils';
import Loader from './components/common/spinner';
import AppRoutes from './AppRoutes';
import { defaultTheme, themeStorageKey } from './constants/defaultValues';
import { ToastContainer } from 'react-toastify';
// import './assets/css/sass/_gogo.style.scss';
import 'react-toastify/dist/ReactToastify.css';
import ErrorBoundary from './components/common/ErrorBoundary';

window.addEventListener('message', (event) => {
  if (event.origin !== window.origin) {
    return;
  }

  if (!event.data.source || event.data.source !== 'wg-iframe-height') {
    return;
  }

  const el = document.getElementById(event.data.id);
  if (!el) {
    return;
  }

  el.style.height = `${event.data.height}px`;
}, false);

function App() {
  useEffect(() => {
    document.body.classList.add('ltr');

    // Add theme class to body
    let theme = localStorage.getItem(themeStorageKey) || defaultTheme;

    // We previously used "light.purple" as the default, now it's just "light" or "dark".
    if (theme !== 'dark' && theme !== 'light') {
      theme = 'light';
    }

    setTheme(theme);
  }, []);

  const currentAppLocale = AppLocale.en;

  return (
    <div className="h-100">
      <ErrorBoundary>
        <Loader />
        <IntlProvider
          textComponent="span"
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <ToastContainer />
          <Router>
            <AppRoutes />
          </Router>
        </IntlProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
