import React, { useEffect, useState } from 'react';
import withRouter from '../../helpers/withRouter';
import { useDispatch, useSelector } from 'react-redux';
import { setInstance, useGetInstancesQuery, useGetSitesQuery } from '../../api/apiSlice';
import { setCookie, getCookie } from '../../../../Utils';

// This component handles switching instances or sites, or where we have missing URL parameters or cookies.
function Reset(props) {
  const [loading, setLoading] = useState(true);

  const {
    children,
    params: {
      instanceId: paramInstanceId,
      siteId: paramSiteId,
    },
  } = props;

  let cookieInstanceId = getCookie('INSTANCE_ID');
  let cookieSiteId = getCookie('SITE_ID');
  const { data: instances } = useGetInstancesQuery();
  const { data: sites } = useGetSitesQuery(paramInstanceId || cookieInstanceId, { skip: !(paramInstanceId || cookieInstanceId) });
  const { instance } = useSelector((s) => s.adminStore.instanceReducer);

  const dispatch = useDispatch();

  // This is a function to clear the cookies and redirect to the root. We do this if we are in a strange state, like
  // the user has requested a deleted instance or site, or the site doesn't match the instance.
  const bail = () => {
    setCookie('INSTANCE_ID', '', 9999, '/');
    setCookie('SITE_ID', '', 9999, '/');
    window.location.href = '/';
  };

  useEffect(() => {
    if (cookieInstanceId && !paramInstanceId && cookieSiteId && !paramSiteId) {
      // We have both cookies but no params: the user has probably just gone to the root domain. We redirect them to
      // the dashboard with params.
      window.location.href = `/dashboards/instance/${cookieInstanceId}/site/${cookieSiteId}`;
      return;
    }

    // URL instance ID should override cookie instance ID, and we should set the cookie to the URL's instance ID.
    if (paramInstanceId && cookieInstanceId && paramInstanceId != cookieInstanceId) {
      setCookie('INSTANCE_ID', paramInstanceId, 9999, '/');
      window.location.reload();
      return;
    }

    if (paramInstanceId && paramInstanceId != cookieInstanceId) {
      setCookie('INSTANCE_ID', paramInstanceId, 9999, '/');
      cookieInstanceId = paramInstanceId;
    }

    if (paramSiteId && paramSiteId != cookieSiteId) {
      setCookie('SITE_ID', paramSiteId, 9999, '/');
      cookieSiteId = paramSiteId;
    }

    if (instances && instances.length > 0) {
      if (paramInstanceId) {
        const tempInstance = instances.find((i) => i.id == paramInstanceId);
        if (tempInstance) {
          dispatch(setInstance({ data: tempInstance, key: 'instance' }));
        } else {
          // We have somehow requested an instance that doesn't exist, perhaps it was deleted. We bail.
          bail();
          return;
        }
      } else if (!cookieInstanceId) {
        // We have no param or cookie instance ID. We set the cookie to the first instance in the list and then
        // reload, and then we will sort out the site on the next load.
        setCookie('INSTANCE_ID', instances[0].id, 9999, '/');
        window.location.reload();
        return;
      }
    }

    if (paramSiteId && instance && sites && sites.length > 0) {
      let tempSite = sites.find((s) => s.id == paramSiteId && s.instance_id == instance.id);
      if (!tempSite) {
        // We have somehow got a site that either doesn't exist or doesn't match the instance. We bail.
        bail();
        return;
      }
    }

    if (!paramSiteId && !cookieSiteId && sites) {
      // We have no site, use the first one in the list.
      setCookie('SITE_ID', sites[0].id, 9999, '/');
      window.location.reload();
      return;
    }

    if (instances && sites) {
      // Phew! We should have both an instance and a site param, we can set the instance and site in the redux store and continue loading.
      const tempInstance = instances.find((i) => i.id == paramInstanceId);
      if (tempInstance) {
        dispatch(setInstance({ data: tempInstance, key: 'instance' }));
      }

      setLoading(false);
    }
  }, [instance, instances, sites]);

  if (loading) {
    return <></>;
  } else {
    // This is the world's ugliest hack, I apologise. See the comments on Instance.content_section_model.
    window.contentSectionModel = instance?.content_section_model;

    return children;
  }
}

export default withRouter(Reset);
