import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Button,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  compact, isEmpty, isEqual,
} from 'lodash-es';
import { NavLink, Outlet } from 'react-router-dom';
import { useIntl, defineMessage } from 'react-intl';
import { QuickScore } from 'quick-score';
import { getPageEditUrl } from '../../constants/routes';
import menuItems from '../../constants/menu';
import setSearchText from '../../redux/searchText/actions';
import EmailPreview from '../../views/email-preview';
import { getCookie, getPageType } from '../../../../Utils';
import SaveAndContinue from '../../components/common/saveAndContinue';
import {
  useGetMasterPagesQuery,
  useGetOrphanPagesQuery,
  useGetPagesQuery,
  useGetSitesQuery,
  useGetEmailsQuery,
  useUpdateInstanceMutation,
  useUpdateSiteMutation,
} from '../../api/apiSlice';
import { useUser } from '../../hooks';
import withRouter from '../../helpers/withRouter';

// The counter is so that we can fetch the page list every time the menu is shown.
function BigNav(props) {
  const { params: { instanceId } } = props;
  const dispatch = useDispatch();
  const { instancesList } = useSelector((state) => state.adminStore.instanceReducer);
  const [emailPreviewModal, setEmailPreviewModal] = useState(false);
  const [emailPreviewId, setEmailPreviewId] = useState(null);
  const [instanceParams, setInstanceParams] = useState({});
  const [originalInstanceParams, setOriginalInstanceParams] = useState({});
  const [siteParams, setSiteParams] = useState({});
  const [originalSiteParams, setOriginalSiteParams] = useState({});
  const user = useUser();

  const searchString = useSelector((s) => s.adminStore.searchTextReducer.text);

  const siteId = props?.siteId ? props?.siteId : getCookie('SITE_ID');

  const { data: pageList = [] } = useGetPagesQuery({ instanceId, siteId }, { skip: isEmpty(instanceId) && isEmpty(siteId) });
  const { data: orphanPages = [] } = useGetOrphanPagesQuery({ instanceId, siteId }, { skip: isEmpty(instanceId) && isEmpty(siteId) });
  const { data: masterPages = [] } = useGetMasterPagesQuery({ siteId, instanceId });
  const { data: emailPages = [] } = useGetEmailsQuery({ instanceId });

  const { data: sites } = useGetSitesQuery(instanceId, { skip: isEmpty(instanceId) });
  const [updateSite] = useUpdateSiteMutation();
  const [updateInstance] = useUpdateInstanceMutation();

  useEffect(() => {
    setInstanceParams(instancesList.find((i) => i.id == instanceId));

    setOriginalInstanceParams(instancesList.find((i) => i.id == instanceId));
  }, [instanceId, instancesList]);

  useEffect(() => {
    setSiteParams(sites.find((s) => s.id == siteId));

    setOriginalSiteParams(sites.find((s) => s.id == siteId));
  }, [siteId, sites]);

  const onClose = () => {
    dispatch(setSearchText(''));
  };

  const intl = useIntl();

  const onHandleButtonClick = (page) => {
    const { id } = page;
    if (getPageType(page) === 'web') {
      window.open(page.page_version.preview_url.match(/https?:\/\//)
        ? page.page_version.preview_url
        : `https://${page.page_version.preview_url}`, '_blank');
    } else {
      setEmailPreviewId(id);
      setEmailPreviewModal(!emailPreviewModal);
    }
  };

  const searchMenuItems = searchString !== '' && (() => {
    const topLevelItems = menuItems(props, user, null, siteId);
    const allItems = [];
    const addItem = (item) => {
      allItems.push(item);
      if (item.subs) {
        item.subs.forEach(addItem);
      }
    };
    topLevelItems.forEach(addItem);

    const itemsWithTitles = allItems.map((item) => {
      const message = defineMessage({ id: item.label });
      item.title = intl.formatMessage(message);
      return item;
    });

    const flatten = (list, arr) => {
      list.push(arr);
      if (Array.isArray(arr.children)) {
        return arr.children.reduce(flatten, list);
      }
      return list;
    };

    pageList.reduce(flatten, []).concat(orphanPages, masterPages, emailPages).forEach((page) => {
      itemsWithTitles.push({
        display: true,
        icon: `fal fa-${page.page_type === 'email' ? 'envelope' : 'file'}`,
        title: page.name,
        component: (
          <span className="actions">
            <NavLink
              className="me-5 text-decoration-none"
              to={getPageEditUrl(
                page.page_version.id,
                page.id,
                getPageType(page),
                props,
              )}
              onClick={onClose}
            >
              <span className="me-3">Edit</span>
              <i className="fa-light fa-fw fa-pen edit" />
            </NavLink>
            <Button
              className="custom-simple-txt-btn me-5"
              onClick={() => {
                onHandleButtonClick(page);
              }}
            >
              <span className="me-3">View</span>
              <i className="fa-light fa-fw fa-up-right-from-square open" />
            </Button>
          </span>
        ),
      });
    });
    console.log(itemsWithTitles);
    const qs = new QuickScore(itemsWithTitles, ['title']);
    const results = qs.search(searchString);

    return compact(results.map((element) => {
      const { item, score } = element;

      if (score < 0.2) {
        return <></>;
      }

      return (
        <Card className="mb-4" key={item.id}>
          <CardBody>
            <CardTitle>
              {!item.component && item.to
                ? (
                    <NavLink to={item.to} className="text-decoration-none" onClick={onClose}>
                      <i className={`${item.icon} me-3`} />
                      {item.title}
                      <i className="fa-light fa-arrow-right ms-3" />
                    </NavLink>
                  )
                : (
                    <>
                      <i className={`${item.icon} me-3`} />
                      {item.title}
                    </>
                  )}
            </CardTitle>
            {typeof item.component === 'function'
              ? item.component(instanceParams, setInstanceParams, siteParams, setSiteParams)
              : item.component}
          </CardBody>
        </Card>
      );
    }));
  })();

  const onHandleSave = async () => {
    if (!isEqual(instanceParams, originalInstanceParams)) {
      updateInstance({ ...instanceParams, id: instanceId });
    }

    if (!isEqual(siteParams, originalSiteParams)) {
      updateSite({ ...siteParams, instance_id: instanceId });
    }
  };

  const displaySave = !isEqual(instanceParams, originalInstanceParams) || !isEqual(siteParams, originalSiteParams);

  return (
    <Container className="big-nav">
      <Row className="big-nav-header">
        <Col>
          <SaveAndContinue
            addNew
            onSave={onHandleSave}
            // using invisible (which equals visibility: hidden) so that the button takes up its height so that the
            // form doesn't jump around vertically.
            className={!displaySave && 'invisible'}
          />
        </Col>
        <Col className="text-end me-4">
          <i className="fal fa-2xl fa-times cursor-pointer" onClick={onClose} />
        </Col>
      </Row>

      {searchString !== '' && (
        <Row className="mt-4">
          <Col>{searchMenuItems}</Col>
        </Row>
      )}

      {emailPreviewModal && !isEmpty(emailPreviewId) && (
        <EmailPreview
          closeModal={() => onHandleButtonClick({ id: null })}
          id={emailPreviewId}
          instanceId={instanceId}
          emailPreviewModal={emailPreviewModal}
        />
      )}

      <Outlet />
    </Container>
  );
}

export default withRouter(BigNav);
