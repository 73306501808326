import React, { useEffect } from 'react';
import { setPageTitle, displayError, addGoogleFont, getFontSize, displaySuccess } from '../../../../../../Utils';
import HeaderComponent from '../../../../components/common/header-component';
import { settingRoutes, siteCenter, replacePathParams } from '../../../../constants/routes';
import CommonApi from '../../../../api/common';
import TableComponent from '../../../../components/common/Table';
import { Button, Card, CardBody } from 'reactstrap';
import confirm from '../../../../components/common/confirm';
import { isEmpty, map, get } from 'lodash-es';
import hexToRGBA from '../../../../components/common/color-picker/hexToRgba';
import withRouter from '../../../../helpers/withRouter';
import { useDeleteTextStyleMutation, useGetTextStylesQuery } from '../../../../api/apiSlice';
import BottomActionToolbar from '../../../../components/common/toolbar';
import CustomButton from '../../../../components/common/button';
import { addRecentItem } from '../../../../../../UtilsTS';
import { useInstance, useSite } from '../../../../hooks';

function TextStyleListing(props) {
  const { navigate } = props;
  const instance = useInstance();
  const site = useSite();
  const siteId = get(site, 'id', null);
  const instanceId = get(instance, 'id', null);
  const { data: textStyleList } = useGetTextStylesQuery(siteId, { skip: isEmpty(siteId) });
  const [deleteTextStyle] = useDeleteTextStyleMutation();
  
  useEffect(() => {
    setPageTitle('Text Styles');
  }, []);

  useEffect(() => {
    if (!isEmpty(textStyleList)) {
      addGoogleFont(map(textStyleList, (f) => ({
        fontName: f.fonts,
        fontWeight: f.font_weight,
      })));
    }
  }, [textStyleList]);

  const onDeleteAction = async (id) => {
    const checkParams = { instanceId, objectId: id, objectType: 'TextStyle' };
    const checkResponse = await CommonApi.checkBeforeDelete(checkParams);
    let result;
    try {
      if (undefined !== checkResponse) {
        const { data: { data: { total, desc } } } = checkResponse;
        result = await confirm({
          title: <b>Confirm!</b>,
          message: (
            <>
              <b>Total Uses:</b>
              {' '}
              {`${total}`}
              <p>
                {' '}
                {desc.map((val) => <li key={val}>{val}</li>)}
              </p>
              <p>Are you sure?</p>
            </>
          ),
          confirmText: 'Yes',
          confirmColor: 'success',
          cancelColor: 'btn btn-danger',
          cancelText: 'No',
        });
      }
      if (result) {
        const sendParams = { id, siteId };
        const res = await deleteTextStyle(sendParams);
        if (res && res?.data?.meta?.is_success) {
          displaySuccess(res?.data?.meta?.messages);
        }
      }
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  };

  const presetColors = site && get(site, 'colors', []);

  const getBackgroundColor = (color) => {
    const realColor = getColor(color);
    const rgbaColor = hexToRGBA(realColor);

    if (rgbaColor.r * 0.299 + rgbaColor.g * 0.587 + rgbaColor.b * 0.114 > 186) {
      return '#000000';
    }
    return '#ffffff';
  };

  const getColor = (color) => {
    if (presetColors && color < 0) {
      const presetColor = presetColors.find((c) => Math.abs(color) === c.id);
      if (presetColor) {
        return presetColor.hex;
      }
    }
    return color;
  };

  const onEditAction = (id) => {
    navigate(replacePathParams(
      siteCenter.editTextStyle,
      [
        { key: 'id', value: id },
        { key: 'siteId', value: siteId },
      ],
      props,
    ));
  };

  const dataTableColumns = () => ([
    {
      Header: 'Name',
      id: 'name',
      accessor: (d) => (
        <div style={{
          fontFamily: d.fonts,
          fontSize: getFontSize(d),
          fontWeight: d.font_weight,
          fontStyle: d.font_style,
          color: getColor(d.color),
          backgroundColor: getBackgroundColor(d.color),
          padding: '4px 6px',
        }}
        >
          {d.name}
        </div>
      ),
    },
    {
      Header: 'Type',
      id: 'type',
      accessor: (d) => d.type,
    },
    {
      Header: 'Color',
      id: 'color',
      accessor: (d) => {
        if (presetColors && d.color < 0) {
          const presetColor = presetColors.find((c) => Math.abs(d.color) === c.id);
          if (presetColor) {
            return `${presetColor.label} (${presetColor.hex})`;
          }
        }
        return d.color;
      },
    },
    {
      Header: 'Font',
      id: 'fonts',
      accessor: (d) => (`${d.fonts} ${d.fallback_font ? ` ⤷ ${d.fallback_font}` : ''}`),
    },
    {
      Header: 'Tag',
      id: 'tag',
      accessor: (d) => d.tag,
    },
    {
      Header: 'Action',
      id: 'action',
      accessor: (d) => (
        <>
          <Button className="custom-simple-icon" color="primary" size="xs" onClick={() => onEditAction(d.id)}>
            <i className="fal fa-pen" />
          </Button>
          {' '}
          <Button
            className="custom-simple-icon"
            color="danger"
            size="xs"
            onClick={() => onDeleteAction(d.id, d.site_id)}
          >
            <i className="fal fa-trash" />
          </Button>
        </>
      ),
    },
  ]);

  if (siteId) {
    addRecentItem({
      instance_id: instanceId,
      site_id: siteId,
      type: 'text-styles',
    });
  }

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: replacePathParams(settingRoutes.dashboard, [], props) },
    {
      name: 'Site Centre',
      url: replacePathParams(siteCenter.pageList, [], props),
    },
    {
      name: 'Design Options',
      url: replacePathParams(siteCenter.designOption, [], props),
    },
    { name: 'Text Styles', url: '' },
  ];

  return (
    <>
      <HeaderComponent
        setPath={{
          backBtnName: 'Back',
          staticBreadcrumbData,
          showBreadcrumb: false,
          addNewBtnName: '',
          addNewPath: '',
          headingName: 'Text Styles',
        }}
      />
      <Card className="mb-4">
        <CardBody>
          <TableComponent
            columns={dataTableColumns()}
            data={textStyleList || []}
          />
        </CardBody>
      </Card>
      <BottomActionToolbar
        component={(
          <CustomButton
            color="info"
            className="add-new-button button-md fw-bold"
            title="ADD TEXT STYLE"
            icon="fa fa-plus"
            onClick={() => navigate(replacePathParams(siteCenter.addTextStyle, [
              { key: 'siteId', value: siteId },
              { key: 'instanceId', value: instanceId },
            ]))}
          />
        )}
      />
    </>
  );
}
export default withRouter(TextStyleListing);
