import React, { useEffect, useState } from 'react';
import {
  useEmailPageProofMutation,
  useGetEmailPageVersionsQuery,
  useGetObjectByIdQuery,
  useUpdateUserMutation,
} from '../../../api/apiSlice';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form, FormGroup,
} from 'reactstrap';
import { get, isEmpty } from 'lodash-es';
import { useSelector } from 'react-redux';
import CarbonCopyEmail from '../../../components/common/carbonCopyEmail';
import { displaySuccess, displayError } from '../../../../../Utils';
import { useUser } from '../../../hooks';

const initialState = {
  subject: '',
  from_name: '',
  from_email: '',
  send_to_emails: [],
};

function EmailProof({ pageId, subject, closeModal, instanceId }) {
  const [modal, setModal] = useState(false);
  const [params, setParams] = useState({
    ...initialState,
    subject,
  });

  const { instancesList } = useSelector((state) => state.adminStore.instanceReducer);

  const user = useUser();

  const { data: { page_versions: pageVersions } = {} } = useGetEmailPageVersionsQuery({ instanceId, pageId });
  const templateId = get(pageVersions, '0.template_id');

  const { data: template } = useGetObjectByIdQuery({ type: 'template', id: templateId }, { skip: isEmpty(templateId) });
  const { data: site } = useGetObjectByIdQuery({ type: 'site', id: template?.site_id }, { skip: isEmpty(template?.site_id) });
  const [emailPageProof, { meta: emailProofMeta }] = useEmailPageProofMutation();
  const [updateUser] = useUpdateUserMutation();

  useEffect(() => {
    if (emailProofMeta?.is_success) {
      displaySuccess(emailProofMeta?.messages);
      const tempData = { ...user };
      const currentEmails = Array.isArray(tempData.proof_email_addresses) ? tempData.proof_email_addresses : [];
      const mergeEmails = [...currentEmails, ...params.send_to_emails];
      tempData.proof_email_addresses = [...new Set(mergeEmails)];
      updateUser(tempData);
    }
  }, [emailProofMeta]);

  useEffect(() => {
    if (pageId) {
      setModal(true);
    }
  }, [pageId]);

  useEffect(() => {
    setParams({
      ...params,
      from_name: site?.display_name || get(params, 'from_name', ''),
      from_email: user?.email,
    });
  }, [instancesList, user, site]);

  const toggle = () => {
    closeModal();
    setModal(!modal);
  };

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    setParams({
      ...params,
      [name]: value,
    });
  };

  const onHandleSend = async (e) => {
    e.preventDefault();
    if (isEmpty(params.send_to_emails)) {
      displayError('Please enter a valid email address');
    } else {
      emailPageProof({ ...params, instance_id: instanceId, id: pageId });
    }
  };

  const onHandleEmailChange = (value) => {
    setParams({ ...params, send_to_emails: value });
  };

  return (
    <Modal isOpen={true} toggle={toggle}>
      <ModalHeader toggle={toggle}>Proof</ModalHeader>
      <Form onSubmit={onHandleSend}>
        <ModalBody>
          <FormGroup>
            <Label>From Name:</Label>
            <Input
              name="from_name"
              value={get(params, 'from_name', '')}
              onChange={(e) => onHandleChange(e)}
            />
          </FormGroup>
          <FormGroup>
            <Label>From Email:</Label>
            <Input
              name="from_email"
              value={get(params, 'from_email', '')}
              onChange={(e) => onHandleChange(e)}
            />
          </FormGroup>
          <FormGroup>
            <Label>Subject:</Label>
            <Input
              name="subject"
              value={get(params, 'subject', '')}
              onChange={(e) => onHandleChange(e)}
            />
          </FormGroup>
          <FormGroup>
            <Label>Send To:</Label>
            <CarbonCopyEmail
              placeholder="To"
              id="send_to_emails"
              name="send_to_emails"
              options={user?.proof_email_addresses}
              selectedEmails={params.send_to_emails}
              onChange={(e) => onHandleEmailChange(e)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="pt-3 pe-3 pb-3 ps-3">
          <Button color="danger" onClick={toggle}>Cancel</Button>
          {' '}
          <Button color="success" onClick={onHandleSend}>Send</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export default EmailProof;
